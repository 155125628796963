import request from '@/utils/request_databasesAPI';
import constants from '@/utils/constants';

export async function obtainMetrics(data) {
	return request({
		url: '/metrics',
		method: constants.METHOD_POST,
		data: data,
		headers: {
			'Content-Type': constants.Application_Json,
			Accept: constants.Application_Json
		}
	});
}
