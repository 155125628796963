<template>
	<!-- <v-btn id="pdf-download" text class="btndownloadpdf" @click="mapdownloadpdf" :disabled="saving">
		<v-icon v-if="!saving" class="fw-bold" size="12">fas fa-download </v-icon>
		<v-progress-circular v-else indeterminate :color="primaryColor" style="width: 24px"></v-progress-circular>
	</v-btn> -->
	<v-tooltip top :color="primaryColor" transition="none">
		<template v-slot:activator="{ on, attrs }">
			<v-btn id="pdf-download" text class="btndownloadpdf" @click="mapdownloadpdf" :disabled="saving" v-bind="attrs" v-on="on">
				<v-icon v-if="!saving" class="fw-bold" size="12">fas fa-download </v-icon>
				<v-progress-circular v-else indeterminate :color="primaryColor" style="width: 24px"></v-progress-circular>
			</v-btn>
		</template>
		<span class="fs-regular">{{ $t('map.downloadpdf') }}</span>
	</v-tooltip>
</template>

<script>
//import ol2map from './ol2map';
import 'ol/ol.css';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'mapdownloadpdf',
	props: {
		width: {
			default: 32
		},
		source: {
			type: String,
			default: 'others'
		}
	},
	data() {
		return {
			map: null,
			saving: false,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	mounted() {},
	computed: {},
	watch: {},

	methods: {
		mapdownloadpdf() {
			console.log("It's: ", this.source)
			this.saving = true;
			const dims = {
				a0: [1189, 841],
				a1: [841, 594],
				a2: [594, 420],
				a3: [420, 297],
				a4: [297, 210],
				a5: [210, 148]
			};
			var element = null
			if (this.source == "fullMap") {
				element = document.getElementById('div-fullmap-container');
			} else {
				element = document.getElementById('div-map-container');
			}
			var idsToHide = ['map-tools', 'map-compare-tools', 'map-zoom-reset', 'map-update-control', 'map-base-layer-control', 'vesselcalls-map-control', 'vesselcalls-fullmap-control'];

			if (this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID) {
				element = document.getElementById('div-maps');
			}

			idsToHide.forEach((id) => {
				const el = document.getElementById(id);
				if (el) {
					el.style.display = 'none';
				}
			});

			const attributionsToHide = [];
			document.querySelectorAll('.ol-attribution').forEach((el) => {
				attributionsToHide.push(el);
				el.style.display = 'none';
			});

			html2canvas(element, {
				scale: 2
			}).then((canvas) => {
				idsToHide.forEach((id) => {
					const el = document.getElementById(id);
					if (el) {
						el.style.display = '';
					}
				});

				attributionsToHide.forEach((el) => {
					el.style.display = '';
				});

				const imgData = canvas.toDataURL('image/jpeg');
				const pdf = new jsPDF('landscape', 'mm', 'a4');

				const pdfWidth = dims.a4[0];
				const pdfHeight = dims.a4[1];
				console.log("Canvas", canvas)
				const canvasWidth = canvas.width + 42;
				console.log('canvasWidth', canvasWidth);
				const canvasHeight = canvas.height;
				const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);

				let imgWidth = canvasWidth * ratio;
				let imgHeight = canvasHeight * ratio;
				console.log('imgWidth', imgWidth);

				let x = (pdfWidth - imgWidth) / 2;
				console.log('x', x);

				let y = (pdfHeight - imgHeight) / 2;

				pdf.addImage(imgData, 'JPEG', x, y, imgWidth, imgHeight);
				pdf.save('download.pdf');
				this.saving = false;
			});
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.maptools {
	& .btndownloadpdf {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--negative);
	}
}
</style>
