<template>
	<div class="pt-0 w-100 historic-chart">
		<v-form v-model="pollutantsForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<v-row no-gutters class="px-3">
				<v-col cols="12" :md="showAdvancedDate ? (form.showChartOptions ? 3 : 4) : 6">
					<v-select
						:label="$t('aqi.dateInterval')"
						toplabel
						clearable
						required
						:disabled="form.formDisabled"
						v-model="form.dateInterval"
						:items="intervals"
						@input="submitDateInterval"
						class="form-control-select mt-2 px-1"
					></v-select>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" :md="form.showChartOptions ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.startDate')"
						toplabel
						:required="!form.startDateDisabled"
						v-model="form.startDate"
						:disabled="form.startDateDisabled"
						:min="getFirstDateParameter()"
						:max="getLastDateParameter() ?? Date.now()"
						:tooltipDescription="form.infoStartDate"
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" :md="form.showChartOptions ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.endDate')"
						toplabel
						:required="!form.endDateDisabled"
						v-model="form.endDate"
						:disabled="form.endDateDisabled"
						:min="form.startDate"
						:max="getLastDateParameter() ?? Date.now()"
						:tooltipDescription="form.infoEndDate"
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
				<!-- Design options -->
				<v-col cols="12" class="d-flex justify-start align-center" :md="showAdvancedDate ? 3 : 6" v-if="form.showChartOptions || form.option">
					<v-select
						v-model="form.designOption"
						@input="changeVisualization"
						class="form-control-select mt-2 px-1"
						:items="form.configurations"
						required
						:label="$t('panels.device.designOptions')"
					></v-select>
					<echart-show-interval
						v-if="enableShowIntervals"
						:elasticData="form.chartData"
						:designOption="form.designOption"
						:pollutant="pollutant"
						:showIntervals="form.showIntervals"
						@toggleShowIntervals="toggleShowIntervals"
					></echart-show-interval>
					<echart-export-excel
						v-if="form.designOption && form.designOption.exportable == 1"
						:option="form.option ?? {}"
						:designOption="form.designOption"
						:pollutant="pollutant"
						:dateInterval="form.dateInterval"
						:bgColor="primaryColor"
						iconColor="white"
						:chartInstance="chartInstance"
					></echart-export-excel>
				</v-col>
			</v-row>
		</v-form>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation && !form.option" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<v-chart v-if="form.option" :loading="loadingAnimation" :option="form.option" :style="chartStyle" autoresize ref="historicChart"></v-chart>
	</div>
</template>

<script>
import { search, getDateByFormat, ChartOptions, obtainIndexName } from '@/api/common';
import { lastData } from '@/api/databases_API';
import { query_HistoricDataByParameter } from '@/utils/queries';
import constants from '@/utils/constants';
import VChart from 'vue-echarts';
import EchartExportExcel from './functionalities/EchartExportExcel.vue';
import EchartShowInterval from './functionalities/EchartShowInterval.vue';
import EchartShowIntervalMixin from './functionalities/EchartShowIntervalMixin';

import { use } from 'echarts/core';
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import {
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent,
	BarChart,
	LineChart,
	PieChart,
	CanvasRenderer
]);

//import logo_multireload from './logo_multireload.png';
export default {
	name: 'HistoricChart',
	mixins: [EchartShowIntervalMixin],
	components: {
		VChart,
		EchartExportExcel,
		EchartShowInterval
	},
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],

			form: {
				index: this.pollutant.elasticacronym,
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				chartData: null,
				option: null,
				designOption: null,
				configurations: [],
				showChartOptions: false,
				showIntervals: false
			},
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 }
				/* { text: this.$t('aqi.customInterval'), value: -1 } */
			],

			pollutantsForm: false,
			loadingAnimation: false,
			chartType: '',
			historicalChartData: [],
			chartStyle: {},
			parameterConfiguration: {},
			parameterOption: null,
			saving: false,
			showAdvancedDate: false,
			primaryColor: this.$store.state.global.primaryColor,
			noStatusColor: '#6f7480',
			chartInstance: null
		};
	},
	props: {
		feature: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		pollutants() {
			return this.$store.getters.getParameters;
		},
		historicElasticData() {
			return this.$store.getters.getHistoricElasticData[this.pollutant.elasticacronym];
		},
		formHistoric: {
			get() {
				return this.$store.getters.getHistoricStore[this.form.index];
			},
			set(value) {
				this.$store.commit('setFormHistoricObject', { index: this.form.index, data: value });
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		},
		historicFirstDates() {
			return this.$store.getters.getHistoricFirstDates;
		},
		historicLastDates() {
			return this.$store.getters.getHistoricLastDates;
		},
		selectedTabHistoric: {
			get() {
				return this.$store.getters.getSelectedTabHistoric;
			}
		}
	},

	watch: {
		'form.option': {
			handler(newVal) {
				if (newVal) {
					this.$nextTick(() => {
						if (this.$refs['historicChart']) {
							this.chartInstance = this.$refs['historicChart']; //.echartsInstance;
							console.log('Chart instance initialized:', this.chartInstance);
						} else {
							console.error('Chart instance is not available');
						}
					});
				}
			},
			immediate: true
		},
		selectedTabDevice: {
			handler() {
				//setTimeout(this.resizeChart, 50);
			}
		},
		displayMode: {
			handler(newValue, oldValue) {
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
					if (this.form.chartData && typeof this.form.chartData.message === 'object') {
						this.form.showChartOptions = true;
						if (!this.form.designOption) {
							this.form.designOption = this.form.configurations.find(
								(option) => option.value.id == this.parameterOption.value.id
							).value;
						}
						console.log('call changevisualization: displayMode', this.chart, this.form.designOption);

						if (!this.form.option) this.changeVisualization(this.form.designOption);
					}
				}
			}
		},
		'form.showChartOptions'(val, prev) {
			console.log('watcher chart opt', prev, val);
		},
		'form.showIntervals'(val, prev) {
			console.log('showintervals watcher ', this.displayLocation, prev, val);
		}
		/* feature(val, prev) {
			console.log('feature watcher historic', prev, val);
			this.form = {
				index: this.pollutant.elasticacronym,
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				chartData: null,
				option: null,
				designOption: null,
				configurations: [],
				showChartOptions: false,
				showIntervals: false
			};
		} */
	},
	created() {
		console.log('===== created historicChart ' + this.displayLocation, this.form);
	},
	mounted() {
		console.log('======= mounted formHistoric ' + this.displayLocation, this.formHistoric, this.pollutant);
		this.parameterOption = this.configurationByPollutant(this.pollutant);
		this.parameterConfiguration = this.parameterOption.value.config;
		this.loadDesignOptions();

		this.chartStyle = this.displayLocation == 'card' ? { height: '250px', width: '100%' } : { height: 'calc(70vh - 187px)', width: '85vw' };
		if (this.displayLocation == 'dialog') this.restoreChanges();

		if (this.form.chartData && !this.chart) {
			this.form.showChartOptions = true;
			console.log('call changevisualization: mounted', this.chart, this.form.designOption);
			this.changeVisualization(this.form.designOption);
		}
	},
	updated() {
		console.log('======= ' + this.displayLocation + ' updated', this.formHistoric, this.chart);
	},
	beforeDestroy() {
		console.log('======= beforeDestroy formHistoric ');
		//this.deleteChart();
	},
	methods: {
		deleteChart() {
			this.form.option = null;
		},
		resizeChart() {
			if (this.chart) this.chart.resize();
		},

		initChart(pollutantAcronym, pollutantSymbol, historicPollutantData, elasticacronym, designOption = null) {
			//Proceso datos historicos del contaminante
			let pollutantValues = [];
			let pollutantDays = [];
			console.info('debug historic data', historicPollutantData);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				historicPollutantData.message.forEach((item) => {
					pollutantDays.push(getDateByFormat(item._source[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants));
					pollutantValues.push(parseFloat(item._source[elasticacronym].toFixed(2)));
				});
			}

			//Configuro opciones del grafico
			let option;

			option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration);

			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			option['series'][0].name = pollutantAcronym;

			console.log('apply ranges init', pollutantValues, this.form.designOption, option['xAxis'], option['series'][0]['data']);
			if (Object.hasOwn(option, 'xAxis') && typeof option['xAxis']['data'] === 'string') {
				// FREQUENCY (HISTOGRAM)
				const xAxisFunction = eval(option['xAxis']['data']);
				option['xAxis']['data'] = xAxisFunction(pollutantValues);
				if (typeof option['series'][0].data === 'string') {
					const seriesFunction = eval(option['series'][0].data);
					option['series'][0].data = seriesFunction(pollutantValues);
				}
				option['series'][0].itemStyle = {
					color: this.noStatusColor
				};
			} else if (typeof option['series'][0]['data'] === 'string') {
				// FREQUENCY (PIE CHART)
				const seriesFunction = eval(option['series'][0].data);
				option['series'][0].data = seriesFunction(pollutantValues);
				option.tooltip.formatter = eval(option.tooltip.formatter);
			} else {
				option['legend'] = { data: [pollutantTitle] };
				option['series'][0].name = pollutantTitle;
				if (Object.hasOwn(option, 'xAxis')) option['xAxis']['data'] = pollutantDays;

				let pieces = [];
				let parameterStatus = this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid);

				if (parameterStatus && designOption.applyranges === 1) {
					const statusArray = JSON.parse(parameterStatus.status);
					const colors = JSON.parse(parameterStatus.color);
					statusArray.sort((a, b) => {
						const minimumA = a.min.data;
						const minimumB = b.min.data;
						if (minimumA < minimumB) {
							return -1;
						}
						if (minimumA > minimumB) {
							return 1;
						}
						return 0; // Objects have the same minimum (statement should be unreachable)
					});
					statusArray.forEach((status, i) => {
						pieces.push({
							/* gte: status.minvalue,
							lte: status.maxvalue, */
							[status.min.op]: status.min.data,
							[status.max.op]: status.max.data,
							color: colors[i]
						});
					});

					option['visualMap'] = {
						top: 50,
						right: 50,
						pieces: pieces,
						outOfRange: {
							color: this.noStatusColor
						}
					};
				} else {
					option['series'][0].lineStyle = {
						color: this.noStatusColor
					};
					if (Object.hasOwn(option.series[0], 'areaStyle')) option.series[0].areaStyle = { color: this.noStatusColor };
				}

				option['series'][0].data = pollutantValues.map((value) => {
					const piece = pieces.find((p) => {
						let min = null;
						let max = null;
						if (p.gt) min = 'gt';
						if (p.gte) min = 'gte';
						if (p.lt) max = 'lt';
						if (p.lte) max = 'lte';
						const isEqual = p[min] === p[max];
						if (isEqual) {
							// Aplicar tolerancia de 0.1 si los límites son iguales
							return value >= p[min] && value - 0.1 <= p[max];
						} else {
							if (min && max) {
								return value >= p[min] && value <= p[max];
							} else if (min) {
								return value >= p[min];
							} else if (max) {
								return value <= p[max];
							}
						}
					});

					return {
						value: value,
						itemStyle: {
							color: piece ? piece.color : this.noStatusColor
						}
					};
				});
				option['series'][0].markPoint = {
					data: [
						{ type: 'max', name: 'Max' },
						{ type: 'min', name: 'Min' }
					],
					symbol: 'pin',
					symbolSize: 50,
					label: {
						formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
					}
				};
				option['series'][0].markLine = {
					data: [{ type: 'average', name: 'Avg' }]
				};
			}

			if ('toolbox' in option) {
				option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
				option['toolbox']['feature']['dataView']['lang'] = [
					this.$t('charts.dataView'),
					this.$t('charts.closeView'),
					this.$t('charts.updateData')
				];

				option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
			}

			option['series'][0].type = this.chartType;
			//console.log('show intervals init', this.form.showIntervals, option);
			if (this.form.showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: this.pollutant.thresholdminvalue }, { yAxis: this.pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 3, borderColor: 'red' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			//console.log('set historic option: ', this.displayLocation, option, JSON.stringify(option), option.series[0].data.length);
			return option;
		},

		configurationByPollutant(parameter) {
			/* console.log(
				'config by pollutant',
				this.form.configurations,
				this.parameterConfiguration,
				JSON.parse(parameter.chartconfiguration)['series'][0]['type']
			); */
			this.chartType = JSON.parse(parameter.chartconfiguration)['series'][0]['type'];
			let configuration = {
				text: parameter.chartname,
				value: {
					config: JSON.parse(parameter.chartconfiguration),
					id: parameter.chartacronym,
					applyranges: parameter.applyranges,
					exportable: parameter.exportable,
					showintervals: parameter.showintervals,
					name: parameter.chartname
				}
			};
			return configuration;
		},
		showDesignOptions() {
			this.form.showChartOptions = true;
			if (!this.form.configurations || !this.form.designOption) {
				const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC);
				const promise = search(historicalChartOptions);
				promise.then((historicalChartData) => {
					console.log('Configurations available for tab (HISTORIC): ', historicalChartData.data.data);

					this.historicalChartData = historicalChartData.data.data;
					this.historicalChartData.forEach((item) => {
						let existConfiguration = false;
						if (this.form.configurations.length > 0) {
							this.form.configurations.forEach((configuration) => {
								if (configuration.text == item['visualizationname']) existConfiguration = true;
							});
						}
						if (!existConfiguration) {
							this.form.configurations.push({
								text: item.visualizationname,
								value: {
									config: JSON.parse(item.configuration),
									id: item.acronym,
									applyranges: item.applyranges,
									exportable: item.exportable,
									showintervals: item.showintervals,
									name: item.visualizationname
								}
							});
						}
					});
					this.form.designOption = this.form.configurations.find((option) => option.value.id == this.parameterOption.value.id).value;
					return this.form.configurations;
				});
			}
			return this.form.configurations;
		},

		changeVisualization(design, elasticData = null) {
			this.loadingAnimation = true;
			this.form.showIntervals = false;
			if (!design) {
				design = this.configurationByPollutant(this.pollutant).value;
			}
			if (typeof design.value == 'object') {
				this.chartType = design.value.config['series'][0]['type'];
				this.parameterConfiguration = design.value.config;
			} else {
				this.chartType = design.config['series'][0]['type'];
				this.parameterConfiguration = design.config;
			}
			let designOption = this.form.designOption;
			if (this.historicalChartData.length > 0) {
				let visualization = this.historicalChartData.find((vis) => vis.acronym == design.id);
				if (visualization)
					designOption = {
						config: JSON.parse(visualization.configuration),
						id: visualization.acronym,
						applyranges: visualization.applyranges,
						exportable: visualization.exportable,
						showintervals: visualization.showintervals,
						name: visualization.visualizationname
					};
				console.debug('debug: design visualization 1', designOption, this.form.designOption);
			}
			console.debug('debug: design visualization 2', this.historicalChartData);

			this.form.option = this.initChart(
				this.pollutant.name,
				this.pollutant.symbol,
				elasticData ?? this.form.chartData,
				this.pollutant.elasticacronym,
				designOption
			);
			this.loadingAnimation = false;
		},
		submitDateInterval() {
			let d = new Date();
			if (this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
					this.commitChanges();
				} else {
					this.showAdvancedDate = false;
					this.loadingAnimation = true;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					d.setDate(d.getDate() - this.form.dateInterval);
					this.form.chartData = null;
					let indexName = obtainIndexName(this.feature);
					lastData(
						indexName,
						query_HistoricDataByParameter(this.feature.code, this.pollutant.elasticacronym, d.valueOf(), Date.now())
					).then((elasticData) => {
						if (elasticData.message.length > 0) {
							this.$store.commit('setHistoricElasticData', {
								index: this.pollutant.elasticacronym,
								data: elasticData
							});
							this.form.chartData = elasticData;
							this.showDesignOptions();
							console.log('call changevisualization: submitDateInterval', this.chart, this.form.designOption);
							this.changeVisualization(this.form.designOption, elasticData);
						} else {
							this.$store.commit('setHistoricElasticData', {
								index: this.pollutant.elasticacronym,
								data: null
							});
							this.loadingAnimation = false;
							this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
							this.form.showChartOptions = false;
							this.deleteChart();
						}
						this.commitChanges();
					});
				}
			} else {
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.deleteChart();
				this.form.chartData = null;
				this.form.showChartOptions = false;
				this.loadingAnimation = false;
				this.commitChanges();
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				this.form.endDateDisabled = false;
				if (this.form.endDate) this.submitEndDate();
				else this.commitChanges();
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
		},
		submitEndDate() {
			console.log('info: submit end date ' + this.displayLocation, this.form.endDate);
			if (this.form.startDate && this.form.endDate) {
				this.loadingAnimation = true;
				this.form.chartData = null;

				lastData(
					obtainIndexName(this.feature),
					query_HistoricDataByParameter(
						this.feature.code,
						this.pollutant.elasticacronym,
						Date.parse(this.form.startDate),
						Date.parse(this.form.endDate)
					)
				).then((elasticData) => {
					console.log(
						query_HistoricDataByParameter(this.feature.code, this.pollutant.elasticacronym, this.form.startDate, this.form.endDate)
					);
					if (elasticData.message.length > 0) {
						this.$store.commit('setHistoricElasticData', {
							index: this.pollutant.elasticacronym,
							data: elasticData
						});
						this.form.chartData = elasticData;
						this.form.showChartOptions = true;
						this.form.option = this.initChart(this.pollutant.name, this.pollutant.symbol, elasticData, this.pollutant.elasticacronym);
					} else {
						this.$store.commit('setHistoricElasticData', {
							index: this.pollutant.elasticacronym,
							data: null
						});
						this.loadingAnimation = false;
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
						this.form.showChartOptions = false;
						this.deleteChart();
					}
					this.commitChanges();
				});
			}
			this.commitChanges();
		},
		commitChanges() {
			if (this.displayMode == this.displayLocation) {
				console.warn('log: commiting changes ' + this.displayLocation + '...', { index: this.form.index, data: this.form });
				this.$store.commit('setFormHistoricObject', { index: this.form.index, data: this.form });
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				console.warn(
					'log: restoring changes ' + this.displayLocation + '... ',
					this.$store.getters.getHistoricStore[this.form.index],
					this.formHistoric,
					this.chart,
					this.form.chartData
				);
				this.$store.getters.getHistoricStore[this.form.index]
					? (this.form = this.$store.getters.getHistoricStore[this.form.index])
					: console.error('Note: Problem with historic store', this.$store.getters.getHistoricStore, this.form.index);
				if (!this.form.chartData || !this.form.option) this.deleteChart();
			}
		},
		getFirstDateParameter() {
			return this.historicFirstDates[this.pollutant.elasticacronym] ? this.historicFirstDates[this.pollutant.elasticacronym] : null;
		},
		getLastDateParameter() {
			return this.historicLastDates[this.pollutant.elasticacronym] ? this.historicLastDates[this.pollutant.elasticacronym] : null;
		},
		loadDesignOptions() {
			const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC);
			const promise = search(historicalChartOptions);
			this.form.configurations = [this.parameterOption];
			promise.then((historicalChartData) => {
				console.log('Configurations available for tab (HISTORIC) debug: ', historicalChartData.data.data);

				this.historicalChartData = historicalChartData.data.data;
				this.historicalChartData.forEach((item) => {
					let existConfiguration = false;
					if (this.form.configurations.length > 0) {
						this.form.configurations.forEach((configuration) => {
							if (configuration.text == item['visualizationname']) existConfiguration = true;
						});
					}
					if (!existConfiguration) {
						this.form.configurations.push({
							text: item.visualizationname,
							value: {
								config: JSON.parse(item.configuration),
								id: item.acronym,
								applyranges: item.applyranges,
								exportable: item.exportable,
								showintervals: item.showintervals,
								name: item.visualizationname
							}
						});
					}
				});
				this.form.configurations.sort((a, b) => {
					const elementA = a.text.toUpperCase();
					const elementB = b.text.toUpperCase();
					return elementA.localeCompare(elementB);
				});
				return this.form.configurations;
			});
		}
	}
};
</script>

<style lang="postcss">
.historic-chart,
.historic-notification-chart,
.advanced-chart {
	& .chart-historic {
		height: calc(70vh - 187px);
		width: 85vw;
	}
	& #intervals-btn {
		background-color: var(--gris-50);
		border-radius: 8px;
	}
	& #intervals-btn.active {
		color: var(--azul-primary-100) !important;
		background-color: var(--azul-primary-100) !important;
	}
}
/* Global CSS for historic and dashboard */
.sla-global-content,
.historic-chart,
.advanced-chart {
	& .vue-echarts-inner > div:last-child > div:last-child > div[style*='cursor: pointer'] {
		background-color: var(--azul-primary-100) !important;
		color: white;
		font-size: 14px !important;
		padding: 6px 12px !important;
	}
}
</style>
