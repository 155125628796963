<template>
	<v-row v-if="feature" justify="center">
		<v-dialog v-model="dialog" persistent max-width="85vw" class="card feature-dialog">
			<slot name="header">
				<header class="card__header overflow-x-auto py-2 pl-2">
					<v-row no-gutters class="">
						<v-col cols="8" class="d-flex flex-nowrap align-center pa-0">
							<span class="bg-circle-white size-lg ml-2 mr-n2"></span>
							<v-img
								v-if="featureIcon"
								contain
								aspect-ratio="1"
								max-height="24"
								max-width="24"
								:src="featureIcon"
								:alt="$t('grid.pmdatasourcetype.iconname')"
								style="position: relative; left: -22px !important"
							></v-img>
							<v-progress-circular :width="3" :size="30" color="white" indeterminate class="mr-2" v-else></v-progress-circular>
							<span class="text-uppercase station-code color-white fw-bold pa-0">{{ feature.code }}</span>
							<span
								v-if="feature.spatialmodel != satelliteModel && feature.longitude && feature.latitude"
								class="fw-regular fs-regular color-white pa-0 pl-3 pt-1"
								>{{ feature.longitude }} N, {{ feature.latitude }}E</span
							>
						</v-col>
						<v-col cols="4" class="pa-0">
							<slot name="action-button" class="align-center justify-end card__button"></slot>
						</v-col>
					</v-row>
				</header>
			</slot>
			<div class="card-content-container" id="dialog-container">
				<devicePanel v-if="type == device" :deviceObject="feature" :displayLocation="displayLocation" />
				<notificationPanel v-else-if="type == notification" :notificationObject="feature" :displayLocation="displayLocation" />
			</div>
		</v-dialog>
	</v-row>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';

import constants from '@/utils/constants';
import { getStringFeatureImage } from '@/api/common';

import devicePanel from './device/DeviceIndex.vue';
import notificationPanel from './notification/NotificationIndex.vue';
//import { CARD_CONTAINER_HEIGHT, CARD_CONTAINER_HEIGHT_DIALOG } from '@/lib/variables/panels';

export default {
	mixins: [PuiGridDetailMixin],
	components: {
		devicePanel,
		notificationPanel
	},
	props: {
		feature: {
			type: Object,
			required: true
		},
		vertical: {
			type: Boolean,
			default: true
		},
		type: {
			type: String,
			required: false,
			default: constants.FEATURE_TYPE_DEVICE
		}
		/* displayMode: {
			type: String,
			required: true,
			default: 'card'
		} */
	},
	data() {
		return {
			tab: null,
			device: constants.FEATURE_TYPE_DEVICE,
			notification: constants.FEATURE_TYPE_NOTIFICATION,
			satelliteModel: constants.SATELLITE_MODEL,
			/* cardContainerStyle: {
				height: CARD_CONTAINER_HEIGHT
			},
			dialogContainerStyle: {
				height: '60vh'
			}, */
			deviceKey: 1,
			notificationKey: 2,
			displayLocation: 'dialog',
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		bodyStyle() {
			return {
				//'flex-direction': this.vertical ? 'column' : 'row',
				'padding-top': this.title ? '0px' : '16px'
			};
		},
		featureIcon() {
			return this.getFeatureIcon(this.type);
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialog() {
			return this.$store.getters.getDisplayMode === 'dialog' ? true : false;
		}
	},
	beforeMount() {},
	mounted() {
		console.warn('feature dialog', this.feature, this.displayMode);
		console.warn('debug', document.getElementsByClassName('v-dialog'), 'type', this.type);

		if (document.getElementsByClassName('v-dialog').length > 0) {
			console.log('debug 2', document.getElementById('feature-dialog'), document.getElementsByClassName('v-dialog'));
			document.getElementsByClassName('v-dialog')[0].id = 'feature-dialog-element';
		}
	},
	updated() {
		console.warn('updated');
	},
	methods: {
		getFeatureIcon(featureType) {
			var appProperties = this.$store.getters.getApplication;
			var icon = null;
			switch (featureType) {
				case constants.FEATURE_TYPE_DEVICE:
					icon = getStringFeatureImage(appProperties.urlbase, this.feature.datasourcetypeicon);
					break;
				case constants.FEATURE_TYPE_NOTIFICATION:
					icon = getStringFeatureImage(appProperties.urlbase, this.feature.iconroute);
					break;
				default:
					icon = getStringFeatureImage(appProperties.urlbase, this.feature.datasourcetypeicon);
					break;
			}
			return icon;
		}
	}
};
</script>

<style lang="postcss" scoped>
@import './../../../../../styles/eiffel-variables.pcss';
.card {
	position: absolute;
	bottom: 1.1rem;
	z-index: 2;
	border: 1px solid var(--moderate);
	background: white;
	width: 98%;
	right: 1%;

	&__body {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		padding: 0 1.125em 0.625em 1.125em;
		height: 330px;
		overflow: hidden;
	}

	&__header {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 1.125em 1.125em 0.5em 1.125em;
		min-height: 42px;
		background-color: var(--azul-primary-100);
		& .station-code {
			font-size: 24px !important;
		}
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		align-items: center;
	}

	&__title {
		color: #414141;
		font-size: 1.125rem;
	}

	&__button:hover {
		background: var(--primarycolor);

		& i {
			color: white;
		}
	}
}

#dialog-container {
	transition: all 1s ease-out;
	height: var(--container-height-dialog) !important;
}

.card-content-tabs {
	transition: max-height 1s ease-out;
	max-height: 50px;
}

.v-dialog--active {
	height: 70vh !important;
}

label.v-label.theme--light {
	left: 8px !important;
	top: 5px !important;
}
label.v-label.v-label--active {
	left: 0px !important;
	top: -1px !important;
}
.v-select__selection--comma {
	margin-left: 7px !important;
}

.v-dialog--active.full-screen {
	height: 100vh !important;
	width: 100vw !important;
}
</style>
