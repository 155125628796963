<template>
	<div class="pt-0 w-100">
		<v-form v-model="compareAqiForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<div row wrap>
				<!-- <i class="fas fa-drafting-compass mx-2"></i>
				<i class="fas fa-palette mx-2"></i>
				<i class="fas fa-swatchbook mx-2"></i>
				<i class="fas fa-pencil-ruler mx-2"></i> -->
				<v-layout wrap class="px-3">
					<v-flex xs12 :md6="!showAdvancedDate" :md4="showAdvancedDate">
						<v-select
							:label="$t('aqi.dateInterval')"
							toplabel
							clearable
							required
							:disabled="form.formDisabled"
							v-model="form.dateInterval"
							:items="intervals"
							@input="submitDateInterval"
							class="form-control-select mt-2 px-1"
						></v-select>
						<!-- @input="getPollutantChart" -->
					</v-flex>
					<v-flex v-if="showAdvancedDate" xs12 md4>
						<pui-date-field
							:label="$t('aqi.startDate')"
							toplabel
							:required="!form.startDateDisabled"
							v-model="form.startDate"
							:disabled="form.startDateDisabled"
							:min="firstDateAqi"
							:max="lastDateAqi ?? Date.now()"
							:tooltipDescription="form.infoStartDate"
							@input="inputStartDate"
						></pui-date-field>
					</v-flex>
					<v-flex v-if="showAdvancedDate" xs12 md4>
						<pui-date-field
							:label="$t('aqi.endDate')"
							toplabel
							:required="!form.endDateDisabled"
							v-model="form.endDate"
							:disabled="form.endDateDisabled"
							:min="form.startDate"
							:max="lastDateAqi ?? Date.now()"
							:tooltipDescription="form.infoEndDate"
							@input="submitEndDate"
						></pui-date-field>
					</v-flex>
				</v-layout>
			</div>
		</v-form>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<!--  echarts component -->
		<div ref="aqiechart" class="d-flex justify-center align-center" v-show="!loadingAnimation" :style="chartStyle" />
	</div>
</template>

<script>
import * as echarts from 'echarts';

// eslint-disable-next-line no-unused-vars
import { search, getDateByFormat, obtainIndexName, isSuperAdmin } from '@/api/common';

import { lastData /* getIndexFromElastic */ } from '@/api/databases_API';
import { query_CompareAQIDataByParameter } from '@/utils/queries';
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';

//import constants from '@/utils/constants';

export default {
	name: 'CompareAQIChart',
	mixins: [QueriesSQLMixin],
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],

			form: {
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				designOption: null,
				configurations: []
			},
			chartData: null,
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],

			compareAqiForm: null,
			loadingAnimation: false,
			chartType: '',
			compareAqiChartData: {},
			chartStyle: {},
			parameterConfiguration: {},
			showAdvancedDate: false,
			aqiByDate: null,
			firstDateAqi: null,
			lastDateAqi: null,
			viewName: '"v_pm_aqi_values" AS pav',
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	components: {},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		formData: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		pollutants() {
			return this.$store.getters.getParameters.filter((pollutant) => pollutant.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		pollutant() {
			return this.$store.getters.getParameters.find((pollutant) => pollutant.acronym == 'AQI');
		},
		compareAqiElasticData: {
			get() {
				return this.$store.getters.getCompareAqiElasticData;
			},
			set(value) {
				this.$store.commit('setCompareAqiElasticData', value);
			}
		},
		formCompareAqi: {
			get() {
				return this.$store.getters.getCompareAqiStore;
			},
			set(value) {
				this.$store.commit('setFormCompareAqiObject', value);
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		},
		compareAqiFirstDates() {
			return this.$store.getters.getCompareAqiFirstDates;
		},
		compareAqiLastDates() {
			return this.$store.getters.getCompareAqiLastDates;
		}
	},

	watch: {
		selectedTabDevice: {
			handler(newValue, oldValue) {
				console.log('info: tab historic ' + this.form.index + ' from', oldValue, 'to', newValue);
				setTimeout(this.resizeChart, 50);
			}
		},
		displayMode: {
			handler(newValue, oldValue) {
				if (this.displayLocation === oldValue) {
					this.commitChanges(this.chartData);
				} else {
					this.restoreChanges();
					if (this.chartData && typeof this.chartData.message === 'object') {
						console.log('log: debug design options', this.form.designOption, this.form.configurations);
						if (!this.form.designOption) {
							this.form.designOption = this.form.configurations[0];
						}
						let elasticData = this.compareAqiElasticData.elastic;
						let databaseData = this.compareAqiElasticData.database;
						console.log('debug aqi 208', elasticData, databaseData);
						this.initChart(this.pollutant.name, this.pollutant.symbol, elasticData, databaseData, this.pollutant.elasticacronym);
					}
				}
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
				}
			}
		},
		form: {
			handler(newFormData, oldFormData) {
				console.log('boolean form dateinterval', newFormData.dateInterval, newFormData.dateInterval == oldFormData.dateInterval);
				//console.warn('commiting...', { index: this.form.index, data: newFormData });
				//this.$store.commit('setFormHistoricObject', { index: this.form.index, data: newFormData });

				console.log('FormHistoricObject getter', this.$store.getters.getHistoricStore, this.$store.getters.getHistoricStore[this.form.index]);
				if (this.displayMode === 'dialog') {
					console.warn(
						'dialog height',
						document.getElementById('dialog-container'),
						document.getElementById('dialog-container').clientHeight
					);
				}
			},
			deep: true
		},
		'form.dateInterval': {
			handler(newValue, oldValue) {
				console.debug('date interval watcher', oldValue, newValue);
				if (!newValue) {
					this.loadingAnimation = false;
					this.chartData = null;
					this.showAdvancedDate = false;
				}
			}
		},
		// eslint-disable-next-line no-unused-vars
		'form.designOption': function (newValue, oldValue) {
			console.log('log: debug design option from: ', oldValue, ' to: ', newValue);
		}
	},
	created() {
		console.log('===== created historicChart ' + this.displayLocation, this.formData, this.form);
		//this.load();
	},
	mounted() {
		console.log('======= mounted formHistoric ' + this.displayLocation, this.form);
		this.chartStyle = this.displayLocation == 'card' ? { height: '250px', width: '100%' } : { height: 'calc(70vh - 187px)', width: '85vw' };
		//console.warn(this.$refs['aqiechart'], this.$refs['aqiechart'].clientHeight, this.$refs['aqiechart'].clientWidth);
		this.getCompareAqiDate(this.viewName, this.deviceObject.pmareaid, this.deviceObject.pmorganizationid, 1, 'DESC').then((result) => {
			this.lastDateAqi = result[0]['date_timestamp'] * 1000;
		});
		this.getCompareAqiDate(this.viewName, this.deviceObject.pmareaid, this.deviceObject.pmorganizationid, 1, 'ASC').then((result) => {
			this.firstDateAqi = result[0]['date_timestamp'] * 1000;
			console.log('debug aqi', this.firstDateAqi, this.lastDateAqi);
		});

		if (this.displayLocation == 'dialog') {
			this.restoreChanges();
		}

		console.warn(this.displayLocation + ' init chart in new visualization 2', this.chart, this.chartData, this.compareAqiElasticData);

		if (this.displayLocation == 'dialog') {
			var echartContainer = this.$refs['aqiechart'];
			//Used to make the chart adaptive to height and width, and calculate the height and width of the container through the height and width of the form
			if (echartContainer) {
				echartContainer.style.height = 'calc(70vh - 187px)';
				echartContainer.style.width = '85vw';
			}
		}
		console.log(this.displayLocation + ' historic chart mounted');
		console.log('debug aqi mounted', this.compareAqiElasticData);
		if (
			this.compareAqiElasticData &&
			Object.hasOwn(this.compareAqiElasticData, 'elastic') &&
			Object.hasOwn(this.compareAqiElasticData, 'database')
		) {
			console.log('debug aqi mounted', this.compareAqiElasticData);
			let databaseData = this.compareAqiElasticData.database;
			let elasticData = this.compareAqiElasticData.elastic;
			this.initChart(this.pollutant.name, this.pollutant.symbol, elasticData, databaseData, this.pollutant.elasticacronym);
		}
	},
	updated() {
		console.log('======= ' + this.displayLocation + ' updated', this.form);
	},
	beforeDestroy() {
		this.deleteChart();
	},
	methods: {
		deleteChart() {
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
			window.removeEventListener('resize', this.resizeChart);
		},
		resizeChart() {
			console.log('info: resize chart ' + this.form.index);
			if (this.chart) {
				// Resize chart
				this.chart.resize();
			}
		},

		getIndexPolicy(data) {
			var indexPolicy = '';
			var indexFiltered = [];
			const nullUndefined = data.some((value) => value == null && value == undefined);
			if (typeof data == 'object') {
				if (nullUndefined) {
					indexPolicy = 0;
				} else {
					//indexFiltered = data.filter((element) => !element.includes('notification') && !element.includes('alert'));
					//Revisar en caso de aplicar politicas a notification y alerts.
					console.log('INDICES', data);
					indexFiltered = data.filter((element) => /\d+$/.test(element));
					console.log('INDICES FILTRADOS', indexFiltered);
					if (indexFiltered.length == 0) {
						//Comprobamos undefined por si no se hubiera realizado aun la particion de la policy eliminamos alerts i notifications
						indexPolicy = 0;
					} else if (indexFiltered.length == 1) {
						indexPolicy = indexFiltered[0];
					} else {
						indexPolicy = indexFiltered[-1];
					}
				}
				return indexPolicy;
			}
		},
		submitDateInterval() {
			let d = new Date();
			console.log('log: ', this.displayLocation + ' submitDateInteval', d, this.form.dateInterval);
			if (this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
					this.commitChanges();
				} else {
					this.showAdvancedDate = false;
					this.loadingAnimation = true;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					d.setUTCDate(d.getUTCDate() - this.form.dateInterval);
					d.setUTCHours(0, 0, 0, 0);
					console.warn('debug aqi date', d, d.getUTCDate() - this.form.dateInterval);
					console.warn('debug aqi date', d.valueOf());
					this.deleteChart();
					this.chartData = {};
					/* this.$store.commit('setHistoricElasticData', {
							index: this.pollutant.elasticacronym,
							data: null
						}); */
					var indexName = obtainIndexName(this.deviceObject);
					let aqiPromises = [];
					aqiPromises.push(this.getMaxAqiValue(d.valueOf()));
					Promise.all(aqiPromises).then((resultPromises) => {
						console.log(
							'debug aqi promises',
							resultPromises,
							query_CompareAQIDataByParameter(this.deviceObject.code, this.pollutant.elasticacronym, d.valueOf(), Date.now())
						);
						//let elasticData = resultPromises[0];
						let databaseData = resultPromises[0];
						if (databaseData.length > 0) {
							let promisesElastic = [];
							databaseData.forEach((aqiObj) => {
								let dateOriginal = new Date(aqiObj.datetimestamp * 1000);
								let datePlusOne = new Date(aqiObj.datetimestamp * 1000);
								let dayPlusOne = dateOriginal.getUTCDate() + 1;
								datePlusOne.setUTCDate(dayPlusOne);
								console.log(
									'debug aqi plus one',
									dateOriginal,
									datePlusOne,
									dayPlusOne,
									dateOriginal.valueOf(),
									datePlusOne.valueOf()
								);
								promisesElastic.push(
									lastData(
										indexName,
										query_CompareAQIDataByParameter(
											this.deviceObject.code,
											this.pollutant.elasticacronym,
											dateOriginal.valueOf(),
											datePlusOne.valueOf()
										)
									)
								);
							});

							Promise.all(promisesElastic).then((resultPromises) => {
								console.log('debug aqi promises elastic', resultPromises);
								let elasticDataArray = [];
								resultPromises.forEach((elasticData) => {
									if (elasticData.message.length > 0) {
										elasticDataArray.push(elasticData.message[0]['_source']);
										//this.initChart(this.pollutant.acronym, this.pollutant.symbol, elasticData, this.pollutant.elasticacronym);
									} else {
										elasticDataArray.push({ _source: { airQualityIndex: null, dateObserved_timestamp: null } });
										console.log('inserted null');
									}
								});
								this.$store.commit('setCompareAqiElasticData', elasticDataArray);
								this.loadingAnimation = false;
								if (elasticDataArray.length == databaseData.length) {
									console.log('debug design options', this.form.designOption, this.form.configurations);
									console.log('debug AQI data', elasticDataArray);
									this.initChart(
										this.pollutant.name,
										this.pollutant.symbol,
										elasticDataArray,
										databaseData,
										this.pollutant.elasticacronym
									);
									this.chartData = { elastic: elasticDataArray, database: databaseData };
								} else {
									this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
								}
								console.log('commiting changes');
								this.commitChanges(this.chartData);
							});
						} else {
							this.$puiNotify.info(this.$t('common.apology'), this.$t('aqi.noDataCAMS'));
							this.loadingAnimation = false;
							this.deleteChart();
							this.chartData = {};
							this.commitChanges(this.chartData);
						}
					});
				}
			} else {
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.deleteChart();
				this.chartData = {};
				this.loadingAnimation = false;
				this.commitChanges(null);
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				console.warn(this.displayLocation + ' startDate input: ' + this.form.startDate);
				console.warn('startDate: ' + Date.parse(this.form.startDate));
				this.form.endDateDisabled = false;

				if (this.form.endDate) {
					this.submitEndDate(); // better usability
				} else {
					this.commitChanges();
				}
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
			//this.form.endDate = null;
		},
		submitEndDate() {
			console.log('info: submit end date ' + this.displayLocation, this.form.endDate);
			if (this.form.startDate && this.form.endDate) {
				this.deleteChart();
				this.loadingAnimation = true;
				this.chartData = {};

				let startDate = new Date(this.form.startDate);
				let endDate = new Date(this.form.endDate);
				let startPlusOne = startDate.getUTCDate() + 1;
				let endPlusOne = endDate.getUTCDate() + 1;
				startDate.setUTCHours(0, 0, 0, 0);
				startDate.setUTCDate(startPlusOne);
				endDate.setUTCHours(23, 59, 59, 0);
				endDate.setUTCDate(endPlusOne);

				console.log('debug aqi dates', this.form.startDate, this.form.endDate, startDate, endDate);

				var indexName = obtainIndexName(this.deviceObject);
				let aqiPromises = [];

				aqiPromises.push(this.getMaxAqiValue(startDate.valueOf(), endDate.valueOf()));
				Promise.all(aqiPromises).then((resultPromises) => {
					console.log(
						'debug aqi promises',
						resultPromises,
						query_CompareAQIDataByParameter(this.deviceObject.code, this.pollutant.elasticacronym, startDate, endDate)
					);
					//let elasticData = resultPromises[0];
					let databaseData = resultPromises[0];
					if (databaseData.length > 0) {
						let promisesElastic = [];
						databaseData.forEach((aqiObj) => {
							let dateOriginal = new Date(aqiObj.datetimestamp * 1000);
							let datePlusOne = new Date(aqiObj.datetimestamp * 1000);
							let dayPlusOne = dateOriginal.getUTCDate() + 1;
							datePlusOne.setUTCDate(dayPlusOne);
							console.log('debug aqi plus one', dateOriginal, datePlusOne, dateOriginal.valueOf(), datePlusOne.valueOf());
							promisesElastic.push(
								lastData(
									indexName,
									query_CompareAQIDataByParameter(
										this.deviceObject.code,
										this.pollutant.elasticacronym,
										dateOriginal.valueOf(),
										datePlusOne.valueOf()
									)
								)
							);
						});

						Promise.all(promisesElastic).then((resultPromises) => {
							console.log('debug aqi promises elastic', resultPromises);
							let elasticDataArray = [];
							resultPromises.forEach((elasticData) => {
								if (elasticData.message.length > 0) {
									elasticDataArray.push(elasticData.message[0]['_source']);
									//this.initChart(this.pollutant.acronym, this.pollutant.symbol, elasticData, this.pollutant.elasticacronym);
								} else {
									elasticDataArray.push({ _source: { airQualityIndex: null, dateObserved_timestamp: null } });
									console.log('inserted null');
								}
							});
							this.$store.commit('setCompareAqiElasticData', elasticDataArray);
							this.loadingAnimation = false;
							if (elasticDataArray.length == databaseData.length) {
								console.log('debug design options', this.form.designOption, this.form.configurations);
								console.log('debug AQI data', elasticDataArray);
								this.initChart(
									this.pollutant.name,
									this.pollutant.symbol,
									elasticDataArray,
									databaseData,
									this.pollutant.elasticacronym
								);
								this.chartData = { elastic: elasticDataArray, database: databaseData };
							} else {
								this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
							}
							console.log('commiting changes');
							this.commitChanges(this.chartData);
						});
					} else {
						this.$puiNotify.info(this.$t('common.apology'), this.$t('aqi.noDataCAMS'));
						this.loadingAnimation = false;
						this.deleteChart();
						this.chartData = {};
						this.commitChanges(this.chartData);
					}
				});
			}
			this.commitChanges();
		},
		commitChanges(data) {
			/* if (this.displayMode == this.displayLocation) { */
			console.warn('debug: commiting changes ' + this.displayLocation + '...', this.form);
			this.$store.commit('setFormCompareAqiObject', this.form);
			this.compareAqiElasticData = data;
			/* } else {
				console.log('debug: not commiting changes ' + this.displayLocation, this.displayMode);
			} */
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				console.warn(
					'log: restoring changes ' + this.displayLocation + '...',
					this.compareAqiElasticData,
					this.$store.getters.getCompareAqiStore
				);
				this.form = this.$store.getters.getCompareAqiStore;
				this.chartData = this.compareAqiElasticData;
				if (Object.hasOwn(this.chartData, 'elastic')) {
					this.deleteChart();
					this.initChart(
						this.pollutant.name,
						this.pollutant.symbol,
						this.chartData.elastic,
						this.chartData.database,
						this.pollutant.elasticacronym
					);
				}
			} else {
				console.log('log: not restoring changes ' + this.displayLocation);
			}
		},
		getFirstDateParameter() {
			console.log('compare Aqi first dates', this.compareAqiFirstDates);
			return this.compareAqiFirstDates ? this.compareAqiFirstDates : null;
		},
		getLastDateParameter() {
			console.log('compare Aqi last dates', this.compareAqiLastDates);
			return this.compareAqiLastDates ? this.compareAqiLastDates : null;
		},
		async getMaxAqiValue(startDate, endDate = null) {
			let rulesAqi = [
				{ field: 'disabled', op: 'eq', data: 0 },
				{ field: 'pmareaid', op: 'eq', data: this.deviceObject.pmareaid },
				{ field: 'datetimestamp', op: 'ge', data: Math.floor(startDate / 1000) }
				/* { field: 'date', op: 'eq', data: Math.floor(Date.now() / 1000) } */
			];
			if (endDate) {
				rulesAqi.push({ field: 'datetimestamp', op: 'le', data: Math.floor(endDate / 1000) });
			}
			if (!isSuperAdmin(this.session.profiles[0])) {
				rulesAqi.push({ field: 'pm_organization_id', op: 'eq', data: this.userProperties.organizationid });
			}

			const body = {
				model: 'vluppmaqivalues',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesAqi
				},
				rows: 10000
			};

			//console.log(body);

			const { data } = await search(body);
			console.log('debug aqi', rulesAqi, data.data);
			let aqiFields = {
				ozonevalue: 'o3conc',
				no2value: 'no2conc',
				so2value: 'so2conc',
				pm25value: 'pm2p5conc',
				pm10value: 'pm10conc'
			};
			let aqiByDate = this.groupByDate(data.data);
			aqiByDate.forEach((aqiGroup) => {
				let maxValue = -1;
				let maxConc = 0;
				let index = 0;
				aqiGroup.aqiValues.forEach((aqiValue, i) => {
					if (maxValue == -1 || aqiValue.maxvalue > maxValue) {
						maxValue = aqiValue.maxvalue;
						let maxConcTemp = aqiValue[aqiFields[aqiValue.maxvaluename]];
						//let maxConcTemp = aqiValue[aqiFields['pm25value']];
						if (maxConc == 0 || maxConcTemp > maxConc) {
							maxConc = maxConcTemp;
							index = i;
							console.log('debug aqi aux', aqiValue.maxvalue, aqiValue.maxvaluename, aqiValue[aqiFields[aqiValue.maxvaluename]], i);
						}
					}
				});
				aqiGroup.aqiValues[index]['maxConc'] = parseFloat(maxConc.toFixed(2));
				aqiGroup.aqiValues = aqiGroup.aqiValues[index];
			});
			console.log('debug aqi by date after', aqiByDate);
			this.aqiByDate = aqiByDate;
			return aqiByDate;
		},
		initChart(pollutantAcronym, pollutantSymbol, elasticDataArray, databaseData, elasticacronym) {
			this.loadingAnimation = false;
			this.deleteChart();
			let indexes = ['database', 'elastic'];
			var pollutantValues = { database: [], elastic: [] };
			var pollutantDays = { database: [], elastic: [] };
			console.warn('debug aqi init chart elastic', elasticDataArray, 'database', databaseData);
			elasticDataArray.forEach((elasticData) => {
				/* pollutantDays.elastic.push(
					getDateByFormat(elasticData[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.session.constants)
						.toString()
						.split(' ')[0]
				); */
				pollutantValues.elastic.push(parseFloat(parseFloat(elasticData[elasticacronym]).toFixed(2)));
			});
			databaseData.forEach((dateGroup) => {
				pollutantDays.database.push(dateGroup.datevalue);
				pollutantValues.database.push(dateGroup.aqiValues.maxConc);
			});

			//console.log(pollutantSymbol, elasticData);
			this.chart = echarts.init(this.$refs['aqiechart']);
			window.addEventListener('resize', this.resizeChart);

			var option = {};

			option = this.parameterConfiguration;
			console.warn('log: chart option', option, this.parameterConfiguration);
			// Complete the options for the configuration
			//var chartTitle = 'panels.device.historicTitle_' + pollutantAcronym;
			//option['title']['text'] = this.$t(chartTitle);
			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;

			let axis = 0;
			let data = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let toolbox = {};
			let dataZoom = [];
			let tooltip = {};
			//let pieces = [];
			//let parameterStatus = this.$store.getters.getParameterStatus.filter((status) => status.pmparameterid === this.pollutant.pmparameterid);
			console.log('selected options', pollutantDays, pollutantValues);

			indexes.forEach((index) => {
				//console.warn("pollutant")
				console.log('init chart', index);

				data = { values: pollutantValues[index], days: pollutantDays['database'] };
				//console.warn('DATOS')
				//console.log(data)
				let origin = index == 'database' ? 'CAMS' : 'In situ';
				let markPointData = [{ type: 'average', name: 'Avg' }];
				if (pollutantValues[index].length > 1) {
					markPointData.push({ type: 'min', name: 'Min' });
				}

				series.push({
					name: pollutantAcronym + ' (' + origin + ')',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: markPointData
					},
					markPoint: {
						data: [{ type: 'max', name: 'Max' }],
						symbol: 'pin',
						symbolSize: 50,
						label: {
							formatter: pollutantSymbol ? '{@score} ' + pollutantSymbol : '{@score}'
						}
					},
					label: {
						formatter: pollutantSymbol ? '{@score} ' + pollutantSymbol : '{@score}'
					},
					data: data.values
				});

				axis++;

				xAxis.push({
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						onZero: false
					},
					data: data.days
				});
			});
			yAxis = [{ type: 'value' }];
			toolbox = {
				right: 10,
				feature: {
					restore: {},
					saveAsImage: {}
				}
			};
			dataZoom = [
				{
					startValue: data.days[0]
				},
				{
					type: 'inside'
				}
			];
			tooltip = {
				trigger: 'axis'
			};

			option['legend'] = { data: [pollutantTitle] };
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = toolbox;
			option['dataZoom'] = dataZoom;
			option['tooltip'] = tooltip;

			/* if (parameterStatus.length > 0) {
				parameterStatus.sort((a, b) => {
					const minimumA = a.minvalue;
					const minimumB = b.minvalue;
					if (minimumA < minimumB) {
						return -1;
					}
					if (minimumA > minimumB) {
						return 1;
					}
					return 0;
				});
				parameterStatus.forEach((status) => {
					pieces.push({
						gt: status.minvalue,
						lte: status.maxvalue,
						color: status.color
					});
				});

				option['visualMap'] = {
					top: 50,
					right: 50,
					pieces: pieces,
					outOfRange: {
						color: '#6f7480'
					}
				};
			}  else {
				option['series'] = [
					{
						name: pollutantTitle,
						type: this.chartType,
						data: pollutantValues,
						//color:  this.$store.state.global.primaryColor,
						markPoint: {
							data: [
								{ type: 'max', name: 'Max' },
								{ type: 'min', name: 'Min' }
							],
							symbol: 'pin',
							symbolSize: 50,
							label: {
								formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
							}
						},
						markLine: {
							data: [{ type: 'average', name: 'Avg' }]
						},
						lineStyle: {
							color: '#6f7480'
						},
						itemStyle: {
							color: '#6f7480'
						}
					}
				];
			} */

			console.log('debug aqi option', option, JSON.stringify(option), this.$store.getters.getParameterStatus);
			option && this.chart.setOption(option);
		},
		groupByDate(dataset) {
			let dateConstant = this.session.constants;
			const object = dataset.reduce((r, { date, datetimestamp, ...rest }) => {
				if (!r[date])
					r[date] = {
						date,
						datevalue: getDateByFormat(datetimestamp * 1000, dateConstant)
							.toString()
							.split(' ')[0],
						datetimestamp,
						aqiValues: [rest]
					};
				else r[date].aqiValues.push(rest);
				return r;
			}, {});
			console.log('debug aqi by date', Object.values(object));
			return Object.values(object);
		}
	}
};
</script>

<style scoped>
.topOptions {
	display: flex;
	flex: auto;
	justify-content: space-between;
	align-content: center;
}
</style>
