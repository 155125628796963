<template>
	<div>
		<prediction-chart :deviceObject="deviceObject" :displayLocation="displayLocation" :pollutants="pollutants" />
	</div>
</template>

<script>
import PredictionChart from '@/components/echart/PredictionChart.vue';

export default {
	name: 'devicePrediction',
	components: {
		PredictionChart
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			tab: 2
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		}
	},
	methods: {}
};
</script>

<style lang="postcss"></style>
