<template>
	<v-card class="pa-0 overflow-x-hidden overflow-y-hidden" :style="formStyle">
		<v-row class="pa-2 ma-0">
			<v-col md6 class="pa-0 ma-0">
				<v-row v-if="isDialog" class="pa-0 ma-0 mt-5">
					<v-col class="pa-0 ma-0">
						<v-card-text class="pt-2"> NO2: </v-card-text>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d1" toplabel :label="xaiDate[0]" />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d2" toplabel :label="xaiDate[1]" />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d3" toplabel :label="xaiDate[2]" />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d4" toplabel :label="xaiDate[3]" />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d5" toplabel :label="xaiDate[4]" />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d6" toplabel :label="xaiDate[5]" />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d7" toplabel :label="xaiDate[6]" />
					</v-col>
				</v-row>
				<v-row v-if="!isDialog" class="pa-0 ma-0 mt-5">
					<v-col class="pa-0 ma-0">
						<v-card-text class="pt-2"> NO2: </v-card-text>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d1" toplabel />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d2" toplabel />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d3" toplabel />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d4" toplabel />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d5" toplabel />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d6" toplabel />
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.no2.d7" toplabel />
					</v-col>
				</v-row>
				<v-row class="d-flex align-top pa-0 ma-0">
					<v-col class="pa-0 ma-0">
						<v-card-text class="pt-2"> O3: </v-card-text>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.o3.d1" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.o3.d2" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.o3.d3" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.o3.d4" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.o3.d5" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.o3.d6" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.o3.d7" toplabel></pui-number-field>
					</v-col>
				</v-row>
				<v-row class="d-flex align-top pa-0 ma-0">
					<v-col class="pa-0 ma-0">
						<v-card-text class="pt-2"> SO2: </v-card-text>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.so2.d1" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.so2.d2" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.so2.d3" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.so2.d4" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.so2.d5" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.so2.d6" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.so2.d7" toplabel></pui-number-field>
					</v-col>
				</v-row>
				<v-row class="d-flex align-top pa-0 ma-0">
					<v-col class="pa-0 ma-0">
						<v-card-text class="pt-0"> Wind Dir: </v-card-text>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windDirection.d1" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windDirection.d2" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windDirection.d3" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windDirection.d4" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windDirection.d5" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windDirection.d6" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windDirection.d7" toplabel></pui-number-field>
					</v-col>
				</v-row>
				<v-row class="d-flex align-top pa-0 ma-0">
					<v-col class="pa-0 ma-0">
						<v-card-text class="pt-0"> Wind Sp: </v-card-text>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windSpeed.d1" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windSpeed.d2" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windSpeed.d3" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windSpeed.d4" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windSpeed.d5" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windSpeed.d6" toplabel></pui-number-field>
					</v-col>
					<v-col class="pa-0 ma-0">
						<pui-number-field v-model="form.windSpeed.d7" toplabel></pui-number-field>
					</v-col>
				</v-row>
				<div v-if="isDialog">
					<v-row class="d-flex align-top pa-0 ma-0">
						<v-col class="pa-0 ma-0">
							<v-card-text class="pt-2"> AQI: </v-card-text>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d1" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d2" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d3" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d4" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d5" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d6" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d7" toplabel></pui-number-field>
						</v-col>
					</v-row>
					<v-row class="d-flex justify-end">
						<v-btn dark class="white--text primary" @click="getPredictionValue" style="margin-right: 20px">Submit</v-btn>
					</v-row>
				</div>
			</v-col>
			<v-col md6>
				<v-row v-if="isDialog" style="height: 100%">
					<div ref="xaiechart" class="d-col justify-center" style="margin-top: 20px; width: 100%; height: 90%" />
				</v-row>
				<v-row v-if="!isDialog" style="height: 80%">
					<div ref="xaiechart" class="d-col justify-center" style="margin-top: 20px; width: 100%; height: 100%" />
				</v-row>
				<div v-if="!isDialog">
					<v-row style="margin-bottom: -20px; margin-left: 20px; margin-right: 5px">
						<v-col class="pa-0 ma-0">
							<v-card-text class="pt-2"> AQI: </v-card-text>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d1" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d2" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d3" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d4" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d5" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d6" toplabel></pui-number-field>
						</v-col>
						<v-col class="pa-0 ma-0">
							<pui-number-field v-model="form.airQualityIndex.d7" toplabel></pui-number-field>
						</v-col>
					</v-row>
					<v-row class="d-flex justify-end">
						<v-btn dark class="white--text primary" @click="getPredictionValue" style="margin-right: 20px">Submit</v-btn>
					</v-row>
				</div>
			</v-col>
		</v-row>
		<!--WORKING MODAL DIALOG-->
		<v-dialog v-model="loading" persistent max-width="35%">
			<v-card>
				<v-card-title class="text-h5 justify-center">
					{{ $t('common.actionInProgress') }}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-container fluid fill-height>
						<v-layout justify-center align-center>
							<v-progress-circular indeterminate :color="primaryColor"> </v-progress-circular>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import * as echarts from 'echarts';

import { getPredictions } from '../../api/databases_API';
import { getDateByFormat } from '@/api/common';

export default {
	components: {},
	data() {
		const xaiDate = [];
		let today = new Date();
		for (let i = 1; i <= 7; i++) {
			let date = new Date(today);
			date.setDate(today.getDate() - i);
			const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
			const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
			formattedDate.replace(/\//g, '-');
			xaiDate.push(formattedDate);
		}
		return {
			xaiDate,
			form: {
				no2: {
					d1: 0,
					d2: 0,
					d3: 0,
					d4: 0,
					d5: 0,
					d6: 0,
					d7: 0
				},
				o3: {
					d1: 0,
					d2: 0,
					d3: 0,
					d4: 0,
					d5: 0,
					d6: 0,
					d7: 0
				},
				so2: {
					d1: 0,
					d2: 0,
					d3: 0,
					d4: 0,
					d5: 0,
					d6: 0,
					d7: 0
				},
				windDirection: {
					d1: 0,
					d2: 0,
					d3: 0,
					d4: 0,
					d5: 0,
					d6: 0,
					d7: 0
				},
				windSpeed: {
					d1: 0,
					d2: 0,
					d3: 0,
					d4: 0,
					d5: 0,
					d6: 0,
					d7: 0
				},
				airQualityIndex: {
					d1: 0,
					d2: 0,
					d3: 0,
					d4: 0,
					d5: 0,
					d6: 0,
					d7: 0
				},
				chartData: {},
				dateInterval: []
			},
			formStyle: {},
			XAIForm: null,
			xaiChart: null,
			loading: false,
			isDialog: false,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		pollutants: {
			type: Array,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		}
	},
	watch: {
		selectedTabDevice: {
			handler(newValue, oldValue) {
				console.log('info: tab XAI from', oldValue, 'to', newValue);
				setTimeout(this.resizeChart, 50);
			}
		},
		displayMode: {
			handler(newValue, oldValue) {
				console.warn('displayMode changed from', oldValue, 'to', newValue);
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
				}
			}
		}
	},
	created() {
		let data = this.$store.getters.getXAIStore;
		if (data.length == 0) {
			this.$store.commit('setFormXAIObject', this.form);
		}
		if (this.displayLocation == 'card') {
			this.isDialog = false;
		} else {
			this.isDialog = true;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.resizeChart();
		});
		this.formStyle = this.displayLocation == 'card' ? { height: '384px' } : { height: 'calc(90vh - 187px)' };
		console.warn(this.formStyle, this.displayLocation);
		if (this.displayLocation == 'dialog') {
			this.restoreChanges();
		}
		this.initChart();
	},
	beforeDestroy() {
		this.deleteChart();
	},
	methods: {
		getPredictionValue() {
			this.loading = true;
			let data = this.getFormValues();
			console.log(data);
			const promise = getPredictions(data);
			promise.then((elasticData) => {
				if (typeof elasticData.message == 'object') {
					const groupedData = {};
					let predictions = elasticData.message['predictions'];
					for (const period in predictions) {
						const pollutantData = predictions[period];
						for (const pollutant in pollutantData) {
							if (pollutant === 'date') continue;

							if (!groupedData[pollutant]) {
								groupedData[pollutant] = [];
							}
							let value = parseFloat(pollutantData[pollutant]);
							groupedData[pollutant].push(value.toFixed(2));
						}
					}
					this.form.chartData = groupedData;
					this.loading = false;
					this.commitChanges();
					this.initChart();
				}
			});
		},
		formatDate(timestamp) {
			const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
			const date = new Date(timestamp);
			const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
			return formattedDate.replace(/\//g, '-');
		},
		getFormValues() {
			this.form.dateInterval.length = 0;
			let today = new Date();
			for (let i = 1; i <= 7; i++) {
				let date = new Date(today);
				date.setDate(today.getDate() - i);
				date = this.formatDate(date.valueOf());
				this.form.dateInterval.push(date);
			}
			let data = {};
			data = {
				'sensor-id': this.deviceObject.code,
				date: this.form.dateInterval,
				no2: [
					parseFloat(this.form.no2.d1),
					parseFloat(this.form.no2.d2),
					parseFloat(this.form.no2.d3),
					parseFloat(this.form.no2.d4),
					parseFloat(this.form.no2.d5),
					parseFloat(this.form.no2.d6),
					parseFloat(this.form.no2.d7)
				],
				so2: [
					parseFloat(this.form.so2.d1),
					parseFloat(this.form.so2.d2),
					parseFloat(this.form.so2.d3),
					parseFloat(this.form.so2.d4),
					parseFloat(this.form.so2.d5),
					parseFloat(this.form.so2.d6),
					parseFloat(this.form.so2.d7)
				],
				o3: [
					parseFloat(this.form.o3.d1),
					parseFloat(this.form.o3.d2),
					parseFloat(this.form.o3.d3),
					parseFloat(this.form.o3.d4),
					parseFloat(this.form.o3.d5),
					parseFloat(this.form.o3.d6),
					parseFloat(this.form.o3.d7)
				],
				windDirection: [
					parseFloat(this.form.windDirection.d1),
					parseFloat(this.form.windDirection.d2),
					parseFloat(this.form.windDirection.d3),
					parseFloat(this.form.windDirection.d4),
					parseFloat(this.form.windDirection.d5),
					parseFloat(this.form.windDirection.d6),
					parseFloat(this.form.windDirection.d7)
				],
				windSpeed: [
					parseFloat(this.form.windSpeed.d1),
					parseFloat(this.form.windSpeed.d2),
					parseFloat(this.form.windSpeed.d3),
					parseFloat(this.form.windSpeed.d4),
					parseFloat(this.form.windSpeed.d5),
					parseFloat(this.form.windSpeed.d6),
					parseFloat(this.form.windSpeed.d7)
				],
				airQualityIndex: [
					parseFloat(this.form.airQualityIndex.d1),
					parseFloat(this.form.airQualityIndex.d2),
					parseFloat(this.form.airQualityIndex.d3),
					parseFloat(this.form.airQualityIndex.d4),
					parseFloat(this.form.airQualityIndex.d5),
					parseFloat(this.form.airQualityIndex.d6),
					parseFloat(this.form.airQualityIndex.d7)
				]
			};
			return data;
		},
		resizeChart() {
			if (this.xaiChart) {
				this.xaiChart.resize();
			}
		},
		deleteChart() {
			if (!this.xaiChart) {
				return;
			}
			this.xaiChart.dispose();
			this.xaiChart = null;
			window.removeEventListener('resize', this.resizeChart);
		},
		commitChanges() {
			if (this.displayMode == this.displayLocation) {
				this.$store.commit('setFormXAIObject', this.form);
				console.log('log: commiting changes ' + this.displayLocation + this.form);
				this.initChart();
			} else {
				console.log('log: not commiting changes ' + this.displayLocation);
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				this.form = this.$store.getters.getXAIStore;
				this.initChart();
			} else {
				//console.log('log: not restoring changes ' + this.displayLocation);
			}
		},
		initChart() {
			var option;
			this.deleteChart();
			this.xaiChart = echarts.init(this.$refs['xaiechart']);
			window.addEventListener('resize', this.resizeChart);
			let date1 = new Date();
			let date2 = new Date();
			date2.setDate(date1.getDate() + 1);
			let date3 = new Date();
			date3.setDate(date1.getDate() + 2);
			this.dateInterval = [
				getDateByFormat(date1.getTime(), this.userProperties.constants),
				getDateByFormat(date2.getTime(), this.userProperties.constants),
				getDateByFormat(date3.getTime(), this.userProperties.constants)
			];
			option = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let toolbox = {};
			let dataZoom = [];
			let tooltip = {};
			let legend = {};

			//console.warn("pollutant")
			//console.log(this.pollutantObjList)
			let so2 = this.pollutants.find((item) => item.elasticacronym === 'so2');
			let pollutantSO2Title = so2.symbol && so2.symbol.trim().length > 0 ? so2.name + ' (' + so2.symbol + ')' : so2.name;
			let o3 = this.pollutants.find((item) => item.elasticacronym === 'o3');
			let pollutantO3Title = o3.symbol && o3.symbol.trim().length > 0 ? o3.name + ' (' + o3.symbol + ')' : o3.name;
			let no2 = this.pollutants.find((item) => item.elasticacronym === 'no2');
			let pollutantNO2Title = no2.symbol && no2.symbol.trim().length > 0 ? no2.name + ' (' + no2.symbol + ')' : no2.name;
			//console.log(values)
			series = [
				{
					name: pollutantO3Title,
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: o3.symbol ? '{@score}' + o3.symbol : '{@score}'
					},
					data: this.form.chartData['o3']
				},
				{
					name: pollutantNO2Title,
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: no2.symbol ? '{@score}' + no2.symbol : '{@score}'
					},
					data: this.form.chartData['no2']
				},
				{
					name: pollutantSO2Title,
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: so2.symbol ? '{@score}' + so2.symbol : '{@score}'
					},
					data: this.form.chartData['so2']
				}
			];
			xAxis = {
				type: 'category',
				data: this.dateInterval
			};
			yAxis = [{ type: 'value' }];
			toolbox = {
				right: 10,
				feature: {
					restore: {},
					saveAsImage: {}
				}
			};
			tooltip = {
				trigger: 'axis'
			};
			legend = {};
			option['legend'] = legend;
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = toolbox;
			option['dataZoom'] = dataZoom;
			option['tooltip'] = tooltip;
			console.log(option);
			this.resizeChart();
			option && this.xaiChart.setOption(option);
		}
	}
};
</script>

<style scoped></style>
