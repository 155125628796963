import { search, filterDependOnRoleAndLanguage, getAreas, getDatasourcetype, isSuperAdmin } from '@/api/common';
export default {
	methods: {
		async reloadCriticalStore() {
			let superAdmin = isSuperAdmin(this.session.profiles[0]);
			let result = await Promise.all([
				this.storeActiveAreas(this.userProperties),
				this.storeActiveDatasources(this.userProperties),
				this.storeActiveParameters(this.userProperties.organizationid, superAdmin),
				this.storeParameterStatus(superAdmin)
				/* this.storeHasDevices(this.userProperties, superAdmin), */
				/* this.storeHasAqiPoints(this.userProperties, superAdmin), */
				/* this.storeHasLayers(this.userProperties, superAdmin), */
			]);
			console.log('result critical mappanel', result);
			return result;
		},
		async reloadSecondaryStore() {
			this.storeFormFilters();
			this.storeSeverities();
			return;
		},
		async storeRefreshTime() {
			const filterUser = filterDependOnRoleAndLanguage(this.userProperties);
			const body = {
				model: 'pmrefreshtime',
				filter: filterUser
			};

			const { data } = await search(body);
			if (data.data[0]) {
				var deliveryperiod = data.data[0].refreshtime;
			} else {
				deliveryperiod = null;
			}

			this.$store.commit('setRefreshTime', deliveryperiod);
			return !!data.data[0];
		},
		async storeActiveAreas(userProperties) {
			let result = await getAreas(this.session.language, userProperties);
			this.$store.commit('setAreas', result.data);
			console.log('mappanel areas', result);
			return true;
		},
		async storeActiveDatasources(userProperties) {
			let result = await getDatasourcetype(this.session.language, userProperties);
			this.$store.commit('setDatasourcetype', result.data);
			console.log('mappanel datasources', result);
			return true;
		},
		async storeActiveParameters(organizationid, superAdmin) {
			var rulesFilter = [];

			if (superAdmin) {
				rulesFilter = [{ field: 'disabled', op: 'eq', data: 0 }];
			} else {
				rulesFilter = [
					{ field: 'pmorganizationid', op: 'eq', data: organizationid },
					{ field: 'disabled', op: 'eq', data: 0 }
				];
			}

			let body = {
				model: 'vluppmparameterdatasourcetype',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesFilter
				},
				rows: 1000
			};
			let { data } = await search(body);
			console.log('After get parameters mapp', data.data, superAdmin, organizationid);
			if (data) {
				data.data.sort((a, b) => {
					const acronymA = a.acronym.toUpperCase();
					const acronymB = b.acronym.toUpperCase();
					if (acronymA < acronymB) {
						return -1;
					}
					if (acronymA > acronymB) {
						return 1;
					}
					return 0; // Objects have the same acronym, no need to change their order
				});

				//console.log('After get parameters', data.data);
				this.$store.commit('setParameters', data.data);
			} else {
				this.$store.commit('setParameters', []);
				//console.log('no parameter data');
			}

			return true;
		},
		async storeParameterStatus(superAdmin) {
			let filter;
			if (!superAdmin) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			} else {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'disabled', op: 'eq', data: 0 }]
				};
			}

			const body = {
				model: 'pmstatus',
				filter: filter,
				rows: 1000
			};

			const { data } = await search(body);
			this.$store.commit('setParameterStatus', data.data);
			console.log('store parameter status', data.data);
			return true;
		},
		async storeHasDevices(userProperties, superAdmin) {
			var filterDevice = null;
			if (!superAdmin) {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'vlupdevicespatialmodel',
				filter: filterDevice,
				rows: 999
			};
			let { data } = await search(body);
			this.$store.commit('setDevices', data.data);
			this.$store.commit('setDevicesData', data.data);
			this.$store.commit('setHasDevices', Object.keys(data.data).length > 0);

			return true;
		},
		async storeHasAqiPoints(userProperties, superAdmin) {
			if (this.userProperties.showaqi) {
				var filterAqiPoint = null;
				if (!superAdmin) {
					filterAqiPoint = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
							{ field: 'disabled', op: 'eq', data: 0 }
						]
					};
				}

				let body = {
					model: 'pmaqipoints',
					filter: filterAqiPoint,
					rows: 999
				};
				let { data } = await search(body);
				this.$store.commit('setAqiPoints', data.data);
				this.$store.commit('setHasAqiPoints', Object.keys(data.data).length > 0);
				return true;
			} else {
				return false;
			}
		},
		async storeHasLayers(userProperties, superAdmin) {
			let filter = null;
			if (!superAdmin) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'pmspatialtables',
				filter: filter,
				rows: 999
			};
			let { data } = await search(body);
			this.$store.commit('setHasLayers', data.data.length > 0);

			return true;
		},
		async storeFormFilters() {
			let bodyFilter = {
				model: 'pmfiltercondition',
				rows: 1000,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: []
				}
			};
			let bodyAreaDef = {
				model: 'pmareadefinition',
				rows: 1000,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				}
			};

			let dataFilter = await search(bodyFilter);
			let dataDefinition = await search(bodyAreaDef);
			console.log('After get data - Filter', dataFilter.data.data);
			console.log('After get data - Area Def', dataDefinition.data.data);
			this.$store.commit('setFormFilters', dataFilter.data.data);
			this.$store.commit('setAreaDefinitions', dataDefinition.data.data);
			return true;
		},
		async storeSeverities() {
			let filter = {
				groups: [],
				groupOp: 'and',
				rules: []
			};

			let body = {
				model: 'pmseverity',
				rows: 999,
				filter: filter
			};

			let { data } = await search(body);
			//console.log('After get data - Severity', data.data, body);
			this.$store.commit('setSeverities', data.data);
			return true;
		}
	}
};
