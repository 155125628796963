import store from '@/store/store';

function KPIS_EMPTY_SAMPLE_AREA() {
	var areas = store.getters.getAreas;
	var empty = {};
	var count = 0;

	// eslint-disable-next-line no-unused-vars
	areas.forEach((item) => {
		//console.log(item);
		//count += 1;
		count = item.pmareaid;
		//empty['ok' + count] = 0;
		empty = mapSeverities(store.getters.getSeverities, count);
		//empty['WARN' + count] = 0;
		//empty['ALARM' + count] = 0;
	});
	return empty;
}

function KPIS_EMPTY_SAMPLE_DATASOURCETYPE() {
	var datasources = store.getters.getDatasourcetype;
	var empty = {};
	var count = 0;

	// eslint-disable-next-line no-unused-vars
	datasources.forEach((item) => {
		//console.log(item);
		//count += 1;
		count = item.pmdatasourcetypeid;
		//empty['ok' + count] = 0;
		empty = mapSeverities(store.getters.getSeverities, count);
		//empty['WARN' + count] = 0;
		//empty['ALARM' + count] = 0;
	});
	return empty;
}

export const KPI_EXTRACTOR_AREA = {
	notifications_byArea: (rows) =>
		rows.reduce((r, { area_id, status, count }) => {
			var id = status + area_id;
			r[id] = count;
			return r;
		}, KPIS_EMPTY_SAMPLE_AREA()),

	notifications_header: (rows) => {
		const unique = [...new Set(rows.map((item) => item.area_id))];
		return unique;
	}
};

export const KPI_EXTRACTOR_DATASOURCETYPE = {
	notifications_byDatasourcetype: (rows) =>
		rows.reduce((r, { datasourcetype_id, status, count }) => {
			var id = status + datasourcetype_id;
			r[id] = count;
			return r;
		}, KPIS_EMPTY_SAMPLE_DATASOURCETYPE()),

	notifications_header: (rows) => {
		const unique = [...new Set(rows.map((item) => item.datasourcetype_id))];
		return unique;
	}
};

function mapSeverities(severities, id) {
	let empty = {};
	severities.forEach((severity) => {
		empty[severity.acronym + id] = 0;
	});
	return empty;
}
