/* GENERAL */

/* BASE LAYERS */
import osm from '@/assets/baselayers/OSM.png';
import satellite from '@/assets/baselayers/ESRI.png';
 
/* MAP ICONS */
import arrowtrack from '@/assets/map/arrow_track.svg';

export default {
	baselayers: {
		osm: osm,
		satellite: satellite
	},
	map: {
		arrowtrack: arrowtrack
	}
};
