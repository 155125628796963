<template>
	<v-tooltip top :color="tooltipColor" transition="none">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				class="pa-0 elevation-0 export-btn"
				:class="spacingClass"
				:color="bgColor"
				@click="
					() => {
						$emit('click');
						option && option.series.length > 1 ? downloadExcelMulti() : downloadExcel();
					}
				"
				:height="height"
				:min-width="width"
				v-bind="attrs"
				v-on="on"
				:disabled="saving"
			>
				<i v-if="!saving" class="fas fa-file-export" :style="{ color: iconColor }"></i>
				<v-progress-circular v-else indeterminate style="width: 24px" :style="{ color: iconColor }"></v-progress-circular>
			</v-btn>
		</template>
		<span class="fs-regular">{{ $t('map.exportExcel') }}</span>
	</v-tooltip>
</template>

<script>
import { getDateByFormat, getStatusProperties } from '@/api/common';
import constants from '@/utils/constants';
const ExcelJS = require('exceljs');
export default {
	name: 'EchartExportExcel',
	props: {
		option: {
			type: Object,
			required: true
		},
		designOption: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: false
		},
		dateInterval: {
			required: true
		},
		tooltipColor: {
			type: String,
			default: 'var(--azul-primary-100)'
		},
		bgColor: {
			type: String,
			default: 'var(--azul-primary-100)'
		},
		iconColor: {
			type: String,
			default: '#fff'
		},
		height: {
			default: 38
		},
		width: {
			default: 42
		},
		chartInstance: {
			type: Object,
			required: false
		},
		spacingClass: {
			type: String,
			default: 'mb-1'
		}
	},
	data() {
		return {
			saving: false
		};
	},
	methods: {
		async downloadExcel() {
			console.log('download EXCEL', this.option, this.designOption, this.designOption.id);
			this.saving = true;
			if (this.option) {
				let ws_data = [];
				let headerRow = [];
				let periodRow = [];
				let columnsConfig = [];
				let categories = null;
				let appData = this.$store.getters.getApplication;
				// Acceder a los datos del gráfico
				const option = this.option;
				const seriesData = option.series[0].data;
				const seriesName = option.series[0].name;
				const parameterStatus = this.pollutant
					? this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid)
					: [];
				// Set date period
				if (Array.isArray(this.dateInterval)) {
					// HISTORIC NOTIFICATION: START DATE <-> END DATE
					periodRow = [
						this.$t('common.period') + ':',
						getDateByFormat(this.dateInterval[0], this.userProperties.constants) +
							' - ' +
							getDateByFormat(this.dateInterval[1], this.userProperties.constants)
					];
				} else {
					// HISTORIC CHART: (NOW - DATE INTERVAL) <-> NOW
					let d = new Date();
					d.setDate(d.getDate() - this.dateInterval);
					periodRow = [
						this.$t('common.period') + ':',
						getDateByFormat(d.valueOf(), this.userProperties.constants) +
							' - ' +
							getDateByFormat(Date.now(), this.userProperties.constants)
					];
				}

				switch (this.designOption.id) {
					case constants.VISUALIZATIONS.TIME_SERIES:
					case constants.VISUALIZATIONS.HISTOGRAM:
					case constants.VISUALIZATIONS.AREA_CHART:
						categories = Array.isArray(option.xAxis) ? option.xAxis[0].data : option.xAxis.data;
						console.log('categories: ', this.designOption.id, categories);

						if (parameterStatus) {
							// VISUALIZATION - WITH STATUS RANGES

							headerRow = [
								this.$t('rasterTools.date'),
								seriesName ? seriesName : 'Value',
								this.$t('form.pmstatus'),
								this.$t('pmstatus.color')
							];
							columnsConfig = [
								{
									key: headerRow[0].toLowerCase(),
									width: 26,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[1],
									width: 32,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[2],
									width: 24,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[3],
									width: 14,
									style: { font: { name: 'Montserrat Medium' } }
								}
							];
							ws_data = [
								...categories.map((category, index) => {
									return [
										category,
										seriesData[index].value,
										this.getStatusInRange(seriesData[index], parameterStatus),
										seriesData[index].itemStyle.color
									];
								})
							];
						} else {
							// VISUALIZATION - NO STATUS RANGES
							headerRow = [this.$t('rasterTools.date'), seriesName];
							columnsConfig = [
								{
									key: headerRow[0],
									width: 26,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									key: headerRow[1],
									width: 32,
									style: { font: { name: 'Montserrat Medium' } }
								},
								{
									width: 24
								},
								{
									width: 14
								}
							];
							ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
						}

						break;
					case constants.VISUALIZATIONS.FREQUENCY_HISTOGRAM:
						categories = option.xAxis.data;
						console.log('categories: ', this.designOption.id, categories);
						// FREQUENCY HISTOGRAM
						headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						columnsConfig = [
							{
								key: headerRow[0],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								key: headerRow[1],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								width: 18
							},
							{
								width: 18
							}
						];
						ws_data = [...categories.map((category, index) => [category, seriesData[index]])];
						break;
					case constants.VISUALIZATIONS.FREQUENCY_PIE:
						categories = seriesData.map((item) => item.name);
						console.log('categories: ', this.designOption.id, categories);
						// FREQUENCY PIE CHART
						headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						columnsConfig = [
							{
								key: headerRow[0],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								key: headerRow[1],
								width: 18,
								style: { font: { name: 'Montserrat Medium' } }
							},
							{
								width: 18
							},
							{
								width: 18
							}
						];
						ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
				}

				// Create Workbook and add Worksheet
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet(this.$t('panels.configpannel.vectorialLayers'));

				// Format title region (logo and app name)
				worksheet.mergeCells('A1:A4');
				worksheet.mergeCells('B1:D4');
				const headerCell = worksheet.getCell('B1');
				headerCell.value = appData.appname;
				headerCell.font = { name: 'Montserrat Black', size: 16, bold: true };
				headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
				// Add period row values
				worksheet.getRow(5).values = periodRow;
				worksheet.mergeCells('B5:D5');
				// Add headers row values
				worksheet.getRow(6).values = headerRow;
				worksheet.columns = columnsConfig;

				// Insertar data starting on row 7
				worksheet.addRows(ws_data);
				if (ws_data[0].length > 2) {
					ws_data.forEach((item, index) => {
						const cell = worksheet.getCell(`D${index + 7}`);
						cell.value = item[3];
						cell.font = { color: { argb: 'FF' + item[3].substring(1, 7) }, name: 'Montserrat Medium' };
					});
				}
				// Format & Style
				worksheet.getRow(5).font = { name: 'Montserrat', size: 12, bold: true };
				worksheet.getRow(6).font = { name: 'Montserrat Black', size: 12, bold: true };
				worksheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
				worksheet.getCell('B5').alignment = { vertical: 'middle', horizontal: 'center' };

				if (appData) {
					const response = await fetch(appData.urlbase + appData.iconroute);
					//const response = await fetch(logo_multireload); // LOCAL TESTING
					const arrayBuffer = await response.arrayBuffer();
					const imageId = workbook.addImage({
						buffer: Buffer.from(arrayBuffer),
						extension: 'png'
					});
					console.log('image id', imageId);

					worksheet.addImage(imageId, {
						tl: { col: 0.1, row: 0.1 }, // Slightly offset to avoid overlap
						ext: { width: 75, height: 75 } // Adjust size to fit within the cell
					});
				}

				// Agregar la imagen de la visualización
				if (this.chartInstance && typeof this.chartInstance.getDataURL === 'function') {
					//Agregar fondo blanco a las celdas que contendrán la imagen
					for (let row = 4; row <= 19; row++) {
						for (let col = 6; col <= 17; col++) {
							const cell = worksheet.getCell(row, col);
							cell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'FFFFFFFF' } // Fondo blanco
							};
						}
					}
					const image = this.chartInstance.getDataURL();
					const imageId = workbook.addImage({
						base64: image,
						extension: 'png'
					});
					worksheet.addImage(imageId, {
						tl: { col: 6, row: 4 },
						br: { col: 17, row: 19 }
					});
				} else {
					console.error('chartInstance is not available or getDataURL is not a function');
					console.log('chartInstance is not available or getDataURL is not a function');
				}

				// Generate file
				const buffer = await workbook.xlsx.writeBuffer();
				const blob = new Blob([buffer], { type: 'application/octet-stream' });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = this.pollutant
					? this.pollutant.name + '_' + this.designOption.name + '_' + getDateByFormat(Date.now(), this.userProperties.constants) + '.xlsx'
					: this.designOption.name + '_' + getDateByFormat(Date.now(), this.userProperties.constants) + '.xlsx';
				link.click();
				window.URL.revokeObjectURL(url);
				this.saving = false;
				link.remove();
			}
		},
		async downloadExcelMulti() {
			console.log('download EXCEL MULTI', this.option, this.designOption, this.designOption.id, this.$refs);
			this.saving = true;
			if (this.option && this.designOption) {
				let ws_data = [];
				let headerRow = [];
				let periodRow = [];
				let columnsConfig = [];
				let categories = null;
				let appData = this.$store.getters.getApplication;
				// Acceder a los datos del gráfico
				const option = this.option;
				const optionSeries = option.series.filter((serie) => serie.type !== 'pie');
				// Set date period
				if (Array.isArray(this.dateInterval)) {
					// HISTORIC NOTIFICATION: START DATE <-> END DATE
					periodRow = [
						this.$t('common.period') + ':',
						getDateByFormat(this.dateInterval[0], this.userProperties.constants) +
							' - ' +
							getDateByFormat(this.dateInterval[1], this.userProperties.constants)
					];
				} else {
					// HISTORIC CHART: (NOW - DATE INTERVAL) <-> NOW
					let d = new Date();
					d.setDate(d.getDate() - this.dateInterval);
					periodRow = [
						this.$t('common.period') + ':',
						getDateByFormat(d.valueOf(), this.userProperties.constants) +
							' - ' +
							getDateByFormat(Date.now(), this.userProperties.constants)
					];
				}

				switch (this.designOption.id) {
					case constants.VISUALIZATIONS.TIME_SERIES:
					case constants.VISUALIZATIONS.HISTOGRAM:
						categories = Array.isArray(option.xAxis) ? option.xAxis[0].data : option.xAxis.data;
						console.log('categories viz: ', this.designOption.id, categories);

						// VISUALIZATION - NO STATUS RANGES
						headerRow = [this.$t('rasterTools.date')];
						columnsConfig = [
							{
								key: headerRow[0],
								width: 26,
								style: { font: { name: 'Montserrat Medium' } }
							}
						];
						optionSeries.forEach((serie) => {
							headerRow.push(serie.name);
							columnsConfig.push({
								key: serie.name,
								width: serie.name.length * 2,
								style: { font: { name: 'Montserrat Medium' } }
							});
						});

						ws_data = [
							...categories.map((category, index) => {
								let row = [category];
								optionSeries.forEach((serie) => {
									row.push(serie.data[index]);
								});
								return row;
							})
						];
						console.log('debug ws_data', ws_data);

						break;
				}

				// Create Workbook and add Worksheet
				const workbook = new ExcelJS.Workbook();
				const worksheet = workbook.addWorksheet(this.$t('panels.configpannel.vectorialLayers'));

				// Format title region (logo and app name)
				worksheet.mergeCells('A1:A4');
				worksheet.mergeCells('B1:D4');
				const headerCell = worksheet.getCell('B1');
				headerCell.value = appData.appname;
				headerCell.font = { name: 'Montserrat Black', size: 16, bold: true };
				headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
				// Add period row values
				worksheet.getRow(5).values = periodRow;
				worksheet.mergeCells('B5:D5');
				// Add headers row values
				worksheet.getRow(6).values = headerRow;
				worksheet.columns = columnsConfig;

				// Insertar data starting on row 7
				worksheet.addRows(ws_data);
				/* if (ws_data[0].length > 2) {
					ws_data.forEach((item, index) => {
						const cell = worksheet.getCell(`D${index + 7}`);
						cell.value = item[3];
						cell.font = { color: { argb: 'FF' + item[3].substring(1, 7) }, name: 'Montserrat Medium' };
					});
				} */
				// Format & Style
				worksheet.getRow(5).font = { name: 'Montserrat', size: 12, bold: true };
				worksheet.getRow(6).font = { name: 'Montserrat Black', size: 12, bold: true };
				worksheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };
				worksheet.getCell('B5').alignment = { vertical: 'middle', horizontal: 'center' };

				if (appData) {
					const response = await fetch(appData.urlbase + appData.iconroute);
					//const response = await fetch(logo_multireload); // LOCAL TESTING
					const arrayBuffer = await response.arrayBuffer();
					const imageId = workbook.addImage({
						buffer: Buffer.from(arrayBuffer),
						extension: 'png'
					});
					console.log('image id', imageId);

					worksheet.addImage(imageId, {
						tl: { col: 0.1, row: 0.1 }, // Slightly offset to avoid overlap
						ext: { width: 75, height: 75 } // Adjust size to fit within the cell
					});
				}

				// Agregar la imagen de la visualización
				console.log('chartInstance debug', this.chartInstance, this.$refs);
				if (this.chartInstance && typeof this.chartInstance.getDataURL === 'function') {
					//Agregar fondo blanco a las celdas que contendrán la imagen
					for (let row = 5; row <= 19; row++) {
						for (let col = 4 + optionSeries.length; col <= 15 + optionSeries.length; col++) {
							const cell = worksheet.getCell(row, col);
							cell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'FFFFFFFF' } // Fondo blanco
							};
						}
					}
					const image = this.chartInstance.getDataURL();
					const imageId = workbook.addImage({
						base64: image,
						extension: 'png'
					});
					worksheet.addImage(imageId, {
						tl: { col: 3 + optionSeries.length, row: 4 }, // F4 (0-based index, so F is 5 and 4 is 3)
						br: { col: 15 + optionSeries.length, row: 19 } // R20 (0-based index, so R is 17 and 20 is 19)
					});
				} else {
					console.error('chartInstance is not available or getDataURL is not a function');
					console.log('chartInstance is not available or getDataURL is not a function');
				}

				// Generate file
				const buffer = await workbook.xlsx.writeBuffer();
				const blob = new Blob([buffer], { type: 'application/octet-stream' });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = this.pollutant
					? this.pollutant.name + '_' + this.designOption.name + '_' + getDateByFormat(Date.now(), this.userProperties.constants) + '.xlsx'
					: this.designOption.name + '_' + getDateByFormat(Date.now(), this.userProperties.constants) + '.xlsx';
				link.click();
				window.URL.revokeObjectURL(url);
				this.saving = false;
				link.remove();
			} else {
				console.error('Error: Incorrect data for Excel Export');
			}
		},
		getStatusInRange(data, parameterStatus) {
			let activeStatus = getStatusProperties(parameterStatus, data.value);
			return activeStatus ? activeStatus.name : '-';
		}
	}
};
</script>

<style></style>
