<template>
	<div ref="fullArea" class="dynamictabs d-flex flex-column flex-grow-1" style="min-height: 30% !important">
		<div v-if="shouldRenderScrollButtons" class="tabseparator"></div>

		<div v-mutate="onMutate" ref="scrollArea" :style="scrollAreaStyle">
			<tab-image v-for="(options, index) in dynamicPanels" :key="index" :options="options"></tab-image>
		</div>

		<div v-if="shouldRenderScrollButtons" class="tabseparator"></div>
	</div>
</template>

<script>
import TabImage from './TabImage';

export default {
	name: 'dynamictabs',

	components: {
		TabImage
	},

	data() {
		return {
			shouldRenderScrollButtons: false
		};
	},

	created() {
		window.addEventListener('resize', this.onMutate.bind(this));
	},
	updated() {},
	beforeDestroy() {
		window.removeEventListener('resize', this.onMutate.bind(this));
	},

	computed: {
		dynamicPanels() {
			return this.$store.getters.dynamicPanels;
		},

		scrollAreaStyle() {
			return {
				overflowX: 'hidden',
				overflowY: 'scroll',
				marginRight: '-18px'
			};
		}
	},
	methods: {
		onMutate() {
			if (this.dynamicPanels.length === 0) {
				return;
			}

			const actualHeight = this.dynamicPanels.length * 40;
			let availableHeight = 0;
			if (this.$refs.fullArea) availableHeight = this.$refs.fullArea.clientHeight - 28;

			this.shouldRenderScrollButtons = actualHeight > availableHeight;
		},

		async scrollDown() {
			await this.$nextTick();

			this.$refs.scrollArea.scrollTop = this.$refs.fullArea.clientHeight;
		}
	},

	watch: {
		dynamicPanels: 'scrollDown'
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
.dynamictabs {
	& .tabseparator {
		height: 10px;
		width: 40px;
		text-align: center;
		padding-top: 4px;
		cursor: pointer;
		font-size: 0.8rem;
		color: var(--low);
	}
}
</style>
