<template>
	<v-dialog v-model="computedValue" max-width="85vw" scrollable>
		<v-card class="maplegenddialog pa-0">
			<v-toolbar flat height="44">
				<v-toolbar-title class="legendtitle">{{ $t('legend.legend') }}</v-toolbar-title>
				<v-spacer></v-spacer>

				<v-btn @click="computedValue = false" small depressed min-width="36" width="36" height="36" class="mr-n3">
					<v-icon size="15">far fa-times</v-icon>
				</v-btn>
			</v-toolbar>

			<v-divider />

			<v-card-text class="pa-0 smooth-scroll">
				<div class="d-flex">
					<!-- TOC -->
					<div class="legendindex pa-4 px-5" style="width: min(300px, 30vw)">
						<div class="d-flex flex-column" style="position: absolute">
							<a
								v-for="(item, index) in toc"
								:key="index"
								@click="replaceState"
								:href="item.href"
								class="fs-regular fw-semi"
								:class="{ 'mb-2': index < toc.length - 1 }"
								>{{ item.title }}</a
							>
						</div>
					</div>

					<!-- Legend -->
					<div ref="legendcontainer" class="d-flex flex-column flex-grow-1 legendlist pa-3" style="height: 80%">
						<div v-for="(legendItem, index) in legend" :key="index">
							<div class="legendlisttitle my-2 fs-regular fw-semi" :id="legendItem.id">{{ legendItem.title }}</div>

							<div :class="{ grid: legendItem.grid, 'd-flex flex-column': !legendItem.grid }">
								<item v-for="(legendIcon, index) in legendItem.items" :key="index" v-bind="legendIcon">
									<div v-if="legendIcon.slot">
										<div v-for="(slot, index) in legendIcon.slot" :key="index" :class="slot"></div>
									</div>
								</item>
							</div>
						</div>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Item from './MapLegendDialogItem';
import constants from '@/utils/constants';
import { getIconsTypeIcon } from '@/api/common';

export default {
	name: 'maplegenddialog',

	props: ['value'],

	components: {
		Item
	},

	computed: {
		computedValue: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		},

		toc() {
			return [
				{
					href: '#general',
					title: this.$t('common.general')
				},

				{
					href: '#status',
					title: this.$t('status.status')
				},

				{
					href: '#satelliteimage',
					title: this.$t('panels.configpannel.satelliteimage')
				}
			];
		},

		legend() {
			// Create object for the layers
			const layers = this.$store.getters.layers;
			const layerItems = [];
			Object.keys(layers).forEach((layer) => {
				var item = { icon: this.getLayerIcon(layers[layer]), text: layers[layer].title };
				layerItems.push(item);
			});

			return [
				{
					id: 'general',
					title: this.$t('common.general'),
					items: layerItems
				},

				{
					id: 'status',
					title: this.$t('status.status'),
					items: [
						{
							text: this.$t('status.ok'),
							slot: ['point ok']
						},

						{
							text: this.$t('status.warning'),
							slot: ['point warning']
						},

						{
							text: this.$t('status.alerm'),
							slot: ['point alerm']
						},

						{
							text: this.$t('status.off'),
							slot: ['point off']
						},

						{
							text: this.$t('status.offline'),
							slot: ['point offline']
						}
					]
				},

				{
					id: 'satelliteimage',
					title: this.$t('panels.configpannel.satelliteimage'),
					items: [
						{
							text: this.$t('legend.pixelSize'),
							slot: ['square none']
						}
					]
				}
			];
		}
	},

	methods: {
		replaceState() {
			const route = `/#${this.$route.path}`;
			setTimeout(() => window.history.replaceState(null, null, route), 0);
		},

		getLayerIcon(layer) {
			let iconSource;
			// TODO: Make generic implementation (maybe array of models)
			switch (layer.model) {
				case constants.DEVICES_MODEL:
					iconSource = layer.datasourcetypeicon;
					break;
				case constants.NOTIFICATIONS_MODEL:
					iconSource = getIconsTypeIcon(
						constants.NOTIFICATIONS_ICONS_TYPE,
						constants.NOTIFICATIONS_TAB_ACRONYM,
						this.$store.getters.getIcons
					).route;
					break;
				default:
					iconSource = layer.datasourcetypeicon;
					break;
			}
			return iconSource;
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.maplegenddialog {
	background: var(--negative);

	& .smooth-scroll {
		scroll-behavior: smooth;
	}

	& .grid {
		display: grid !important;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto;
		grid-gap: 0px;
	}

	& .legendtitle {
		font-size: 20px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
	}

	& .legendindex {
		background: var(--very-low);
		& a {
			text-decoration: none;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: var(--high);
			&:link {
				color: var(--high);
			}
			&:visited {
				color: var(--high);
			}
			&:hover {
				color: var(--azul-primary-100);
			}
			&:active {
				color: var(--high);
			}
		}
	}
}
</style>
