import request from '@/utils/request_fileAPI';
import constants from '@/utils/constants';

export function download(data) {
	return request({
		url: '/files/downloadFile',
		method: constants.METHOD_POST,
		data: data,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export function getFiles() {
	return request({
		url: '/files/getList',
		method: constants.METHOD_GET
	});
}

export function realTimeByPollutant(pollutant) {
	return request({
		url: '/files/realTimeByPollutant/' + pollutant,
		method: constants.METHOD_GET
	});
}
