export default {
	computed: {
		/* layerNotificationsActive() {
			console.debug('debug 123', Object.values(this.$store.state.mappanel.layers), this.$store.getters.notificationLayers)
			if (this.$store.getters.notificationLayers.length > 0) {
				this.$store.getters.notificationLayers.forEach((layer) => {
					if (layer.active) {
						console.log('debug 123', layer.active)
						return true
					}
				})
			}
		return false;
	}, */
		layerNotificationsActive() {
			return true;
		},
		layerAqiPointsActive() {
			return this.$store.getters.aqiPointsLayer.active;
		}
	}
};
