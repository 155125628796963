<template>
	<div class="legendlistrow">
		<svg-icon v-if="icon" :icon="icon" :width="20"></svg-icon>
		<slot v-else />
		<div class="legendlistelement fs-regular">{{ text }}</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/map/icon/SvgIcon';

export default {
	props: ['icon', 'text'],

	components: {
		SvgIcon
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.legendlistrow {
	margin-bottom: 3px;
	& .stringline {
		width: 15px;
		height: 4px;
		border-radius: 2px;
		background-color: var(--high);
		float: left;
		margin-top: 9px;
		margin-left: 2px;
		margin-right: 6px;
	}
	& .dashedline {
		width: 7px;
		height: 4px;
		border-radius: 3px;
		background-color: var(--high);
		float: left;
		margin-top: 9px;
		margin-left: 2px;
	}
	& .dashedline2 {
		width: 7px;
		height: 4px;
		border-radius: 3px;
		background-color: var(--high);
		float: left;
		margin-top: 9px;
		margin-right: 7px;
		margin-left: 1px;
	}
	& .legendlistelement {
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--medium);
		padding-top: 4px;
		padding-left: 30px;
	}
	& .point {
		width: 10px;
		height: 10px;
		margin-right: 8px;
		margin-left: 4px;
		border-radius: 10px;
		float: left;
		font-size: 10px;
		margin-top: 5px;
		&.ok {
			background: var(--status-ok);
		}
		&.warning {
			background: var(--status-warning);
		}
		&.alerm {
			background: var(--status-alerm);
		}
		&.off {
			background: var(--status-off);
		}
		&.offline {
			background: var(--status-offline);
		}
	}
	& .square {
		width: 10px;
		height: 10px;
		margin-right: 8px;
		margin-left: 4px;
		float: left;
		font-size: 10px;
		margin-top: 5px;
		&.none {
			border-color: var(--status-off);
			border: solid 1px black;
		}
	}
}
</style>
