import { Icon, Style, Fill, Circle, Stroke } from 'ol/style';
import { getStringFeatureImage } from '@/api/common';
//import store from '@/store/store';
import Text from 'ol/style/Text';

const devicesStylePointFunction = function (feature) {
	var notificationsCount = feature['values_']['notifications'];
	var notificationColor = feature['values_']['severitycolor'];
	var iconSource = feature['values_']['viz'].featureProperties.datasourcetypeicon;
	var opacity = feature['values_']['viz'].iconProperties.opacity;
	var iconScale = 0.4;
	//var appProperties = store.getters.getApplication;
	var styles = [];
	if (feature['values_'].ismobile === 1) {
		var circleStyle = new Style({
			image: new Circle({
				radius: 20,
				fill: new Fill({
					color: '#303E5A80'
				}),
				stroke: new Stroke({
					color: '#303E5A4D',
					width: 3
				})
			})
		});
		styles.push(circleStyle);
	}
	var bgStyle = new Style({
		image: new Circle({
			radius: 16,
			fill: new Fill({
				color: '#fff'
			}),
			stroke: new Stroke({
				color: '#fff',
				width: 3
			})
		})
	});
	styles.push(bgStyle);

	var iconStyle = new Style({
		image: new Icon({
			opacity: opacity,
			scale: iconScale,
			//crossOrigin: '',
			src: getStringFeatureImage('', iconSource)
			//src: getStringFeatureImage(appProperties.urlbase, iconSource)
		})
	});
	styles.push(iconStyle);

	if (notificationsCount > 0) {
		var text = new Text({
			text: notificationsCount.toString(),
			font: 'bold 12px Montserrat, sans-serif',
			fill: new Fill({ color: 'white' }),
			padding: [2, 2, 2, 2],
			offsetY: -15,
			offsetX: 15
		});

		var pointStyle = new Style({
			image: new Circle({
				radius: 10,
				fill: new Fill({
					color: notificationColor ?? '#0C1A36'
				}),
				stroke: new Stroke({
					color: notificationColor ?? '#0C1A36',
					width: 2
				}),
				displacement: [15, 15]
			}),
			text: text
		});
		styles.push(pointStyle);
	}
	return styles;
};

export default devicesStylePointFunction;
