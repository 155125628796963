import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';

import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import { get as getProjection } from 'ol/proj.js';
import { getTopLeft, getWidth } from 'ol/extent.js';
import { BASEMAP_ID } from '@/components/map/sections/map/subcomponents/mapVariables';
import ol2map from '../components/map/sections/map/subcomponents/ol2map';

import Icons from '@/components/icon/icon-mappings';

export default {

	data() {
		return {
			baseLayersControl: []

		};
	},
	created() {
		this.initializeBaseLayers();
	},

	methods: {
		initializeBaseLayers() {

		this.baseLayersControl = [
				{
					title: this.$t('map.osm'),
					layer: this.baseTileLayerOSM(),
					src: Icons['baselayers']['osm']
				},
				{
					title: this.$t('map.satellite'),
					layer: this.baseTileLayerESRI(),
					src: Icons['baselayers']['satellite']
				}
			];
		},

		baseTileLayerOSM() {
			return new TileLayer({
				id: BASEMAP_ID,
				source: new OSM() // tiles are served by OpenStreetMap
			});
		},

		addBaseLayerOSM() {
			var baseLayer = this.baseTileLayerOSM();
			ol2map.addLayer(baseLayer);
		},
	
		baseTileLayerESRI() {
			var epsg3857 = 'EPSG:3857';
			var projection = getProjection(epsg3857);
			var projectionExtent = projection.getExtent();
			const size = getWidth(projectionExtent) / 256;
			var resolutions = new Array(18);
			var matrixIds = new Array(18);
			for (var z = 0; z < 18; ++z) {
				resolutions[z] = size / Math.pow(2, z);
				matrixIds[z] = z;
			}
			var attribution = 'ESRI Imagery/Satellite';

			const esriSource = new WMTS({
				url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/WMTS',
				layer: 'World_Imagery',
				matrixSet: epsg3857,
				format: 'image/png',
				projection: projection,
				tileGrid: new WMTSTileGrid({
					origin: getTopLeft(projectionExtent),
					resolutions: resolutions,
					matrixIds: matrixIds
				}),
				style: 'normal',
				attributions: attribution,
				crossOrigin: 'anonymous'
			});
			return new TileLayer({
				id: BASEMAP_ID,
				source: esriSource
			});
		}
	},
};
