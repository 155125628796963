import { render, staticRenderFns } from "./TabImage.vue?vue&type=template&id=4f97c771&scoped=true"
import script from "./TabImage.vue?vue&type=script&lang=js"
export * from "./TabImage.vue?vue&type=script&lang=js"
import style0 from "./TabImage.vue?vue&type=style&index=0&id=4f97c771&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f97c771",
  null
  
)

export default component.exports