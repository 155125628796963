<template>
	<div v-if="icon" class="svgicon" :class="{ halo }" :style="{ width: computedWidth + 'px', height: computedWidth + 'px' }">
		<span v-if="counterNotification && counterNotification > 0" class="fa-layers-counter icon__notification">{{ counterNotification }}</span>
		<img :src="image" />
	</div>
</template>

<script>
import { getStringFeatureImage } from '@/api/common';
export default {
	name: 'svgicon',

	props: {
		icon: {
			required: true
		},

		width: {
			type: Number,
			default: 25
		},

		counterNotification: {
			type: Number
		},

		halo: {
			type: Boolean,
			default() {
				return false;
			}
		}
	},

	computed: {
		image() {
			return getStringFeatureImage(this.$store.getters.getUrlBaseIcons, this.icon);
			//return Icons[this.group][this.icon];
		},

		computedWidth() {
			return this.width + (this.halo ? 6 : 0);
		}
	}
};
</script>

<style lang="postcss">
@import './../../../styles/eiffel-variables.pcss';
.halo {
	border-radius: 50%;
	background-color: rgba(255, 106, 47, 0.15);
	padding: 3px;
}

.svgicon {
	position: relative;
	float: left;
	& img {
		width: 100%;
		&.colblack {
			filter: saturate(0%) grayscale(50%) brightness(69%) contrast(1000%) !important;
		}
		&.colgrey {
		}
	}
}
.icon__notification {
	background: var(--warning);
	position: absolute;
	min-width: 15px;
	top: -8px;
	right: -8px;
	font-size: 9px;
	border-color: white;
	border-style: solid;
	border-width: 1px;
	border-radius: 12px;
	text-align: center;
	color: black;
	font-weight: 500;
	padding-top: 2px;
}
</style>
