<template>
	<div class="weather-tool">
		<v-tooltip left :color="primaryColor" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text class="btn-weather pa-0 elevation-1" :class="{ 'd-none': !hasWeather }" v-bind="attrs" @click="dialog = true" v-on="on">
					<v-img contain aspect-ratio="1" max-width="32" :src="btnIcon"></v-img>
				</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.weather') }}</span>
		</v-tooltip>
		<v-dialog v-model="dialog" :max-width="width" scrollable persistent>
			<v-card flat>
				<v-card-title class="headline">{{ cityForecast }} - {{ $t('map.weather') + ' (' + startDay + ' - ' + endDay + ')' }}</v-card-title>
				<v-card-text class="pb-3 d-flex flex-nowrap bg-white" style="height: 50vh">
					<ul class="d-flex flex-nowrap bg-white overflow-x-scroll pa-0">
						<li v-for="(day, i) in forecastData" :key="i" class="d-flex flex-column">
							<h2 class="fs-regular fw-bold color-black" style="display: initial; position: sticky; max-width: fit-content; left: 1px">
								{{ getDayName(i) }}
							</h2>
							<div class="d-flex flex-nowrap height-100" style="border-right: 2px solid black">
								<weather-card-item v-for="(hour, i) in day" :key="i" :hourData="hour"></weather-card-item>
							</div>
						</li>
					</ul>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-spacer></v-spacer>
					<v-btn color="var(--azul-primary-100)" class="color-white elevation-0" @click="dialog = false">{{ $t('common.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import WeatherCardItem from './WeatherCardItem';
import { getDateByFormat } from '@/api/common';
//import ol2map from '../ol2map';
import axios from 'axios';
import constants from '@/utils/constants';
export default {
	components: { WeatherCardItem },
	data() {
		return {
			dialog: false,
			weatherData: null,
			btnIcon: null,
			primaryColor: this.$store.state.global.primaryColor,
			forecastData: [],
			cityForecast: null,
			startDay: null,
			endDay: null,
			hasWeather: false
		};
	},
	computed: {
		dateConstant() {
			return this.userProperties ? this.userProperties.constants : constants.DATE_FORMAT_EUROPEAN_FORMAT;
		},
		coordinates() {
			return this.$store.getters.getCenterCoordinates;
		},
		width() {
			let width = '60%';
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					width = '90%';
					break;
				case 'sm':
					width = '80%';
					break;
				case 'md':
					width = '60%';
					break;
				case 'lg':
					width = '55%';
					break;
				case 'xl':
					width = '900px';
					break;
			}
			return width;
		}
	},
	watch: {
		coordinates(val) {
			console.log('coordinates', val);
			if (val) this.initWeather(val);
		}
	},
	mounted() {
		document.addEventListener('keyup', this.toggleEscape);
		/* if (this.coordinates && this.coordinates.isArray()) {
			this.initWeather(this.coordinates);
		} */
		//console.log('debug coordinates', ol2map.getCenterCoordinates());
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.toggleEscape);
	},
	methods: {
		getDayName(index) {
			if (index == 0) return this.$t('common.days.today');
			if (index == 1) return this.$t('common.days.tomorrow');
			let day = new Date().getDay() + index;
			if (day == 7) day = 0;
			return this.$t('common.days.' + day);
		},
		initWeather(coordinates, retry = true) {
			let q = coordinates[1].toFixed(3) + ',' + coordinates[0].toFixed(3);
			axios
				.get('https://api.weatherapi.com/v1/forecast.json?key=98c040a145ef47f4ba153123240807&q=' + q + '&days=3&aqi=no&alerts=no')
				.then((res) => {
					console.log('weather req', res);
					this.weatherData = res.data;
					//this.btnIcon = res.data.current.condition.icon;
					this.cityForecast = res.data.location.name;
					let date = new Date();
					let currentHours = date.getHours();
					let forecastDays = res.data.forecast.forecastday;
					this.startDay = getDateByFormat(forecastDays[0].date_epoch * 1000, this.dateConstant);
					this.endDay = getDateByFormat(forecastDays[forecastDays.length - 1].date_epoch * 1000, this.dateConstant);
					if (this.startDay.split(' ').length > 1) {
						this.startDay = this.startDay.split(' ')[0];
						this.endDay = this.endDay.split(' ')[0];
					}

					forecastDays.forEach((day) => {
						if (this.forecastData.length === 0) {
							day.hour = day.hour.toSpliced(0, currentHours);
							this.btnIcon = day.hour[0].condition.icon;
						}
						let hoursData = this.mappedHours(day);

						//console.log('day hours debug', day.hour);

						this.forecastData.push(hoursData);
						this.hasWeather = true;
					});
					//console.debug('debug forecast', this.forecastData, this.startDay, this.endDay);
				})
				.catch((e) => {
					console.error('ERROR Caught: Weather API request', e);
					this.hasWeather = false;
					if (retry) {
						this.initWeather([-0.3674, 39.473], false);
					}
				});
		},
		mappedHours(day) {
			let hoursData = [];
			day.hour.forEach((hour) => {
				let dataObj = {
					condition: hour.condition,
					temp_c: hour.temp_c,
					time: hour.time.split(' ')[1],
					precip_mm: hour.precip_mm,
					humidity: hour.humidity,
					wind_degree: hour.wind_degree,
					wind_kph: hour.wind_kph
				};
				hoursData.push(dataObj);
			});
			return hoursData;
		},
		toggleEscape(e) {
			//console.log('toggleEscape', e.key, e.key == 'Escape', this.displayMode == 'dialog');
			if (e.key == 'Escape' && this.dialog) this.dialog = false;
		}
	}
};
</script>

<style lang="postcss">
.weather-tool {
	& .btn-weather {
		z-index: 4;
		position: absolute;
		top: 1rem;
		right: 275px;
		margin: 0px;
		width: 32px;
		height: 32px !important;
		min-width: 32px !important;
		background-color: var(--negative);
	}
}
</style>
