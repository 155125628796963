<template>
	<div>
		<advanced-chart :displayLocation="displayLocation" :pollutants="pollutants" :deviceObject="deviceObject" />
	</div>
</template>

<script>
import AdvancedChart from '@/components/echart/AdvancedChart.vue';
export default {
	components: {
		AdvancedChart
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			tab: 3
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		}
	},
	mounted() {
		console.log('mounted device advanced', this.displayMode);
	},
	methods: {}
};
</script>

<style lang="postcss">
.switchoff {
	color: var(--gris-300);

	& .switchoff_icon {
		font-size: 2rem;
	}

	& .switchoff-text {
		font-size: 1rem;
	}
}
</style>
