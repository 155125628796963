<template>
	<div style="padding-top: 10px; width: 100%">
		<v-form v-model="predictionsForm" ref="form">
			<div row wrap>
				<v-layout wrap class="topOptions pt-2" style="height: 45px">
					<!-- Pollutants -->
					<v-flex xs12 md4>
						<v-select
							v-model="form.choosePollutant"
							@input="getPollutant"
							class="form-control-select topLeft"
							:items="pollutants"
							required
							:label="$t('panels.device.choosePollutant')"
						></v-select>
					</v-flex>
				</v-layout>
			</div>
		</v-form>

		<!--  echarts component -->
		<div ref="echart" class="d-flex justify-center" style="height: 250px; width: 100%" />
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as echarts from 'echarts';

// eslint-disable-next-line no-unused-vars
import { search } from '@/api/common';
// eslint-disable-next-line no-unused-vars

import { realTimeByPollutant } from '@/api/file_API';

export default {
	components: {},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		resized: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			predictionsForm: false,
			form: {
				choosePollutant: '0',
				chooseDate: '0'
			},
			pollutant: '',
			pollutantsByDate: [],
			pollutants: [],
			filter_options: [],
			pollutantNoSelected: true,
			chart: null,
			xData: [],
			minSeries: [],
			maxSeries: [],
			parameterConfiguration: {},
			showChartOptions: false,
			configurations: [],
			selected: '',
			chartType: ''
		};
	},
	computed: {
		defaultWidth() {
			if (this.resized && this.resized.width) {
				//return `${this.resized.width - 50}px`;
			}
			return '100%';
		},
		defaultHeight() {
			if (this.resized && this.resized.height) {
				return `${this.resized.height - 50}px`;
			}
			return '100%';
		}
	},
	watch: {
		resized: {
			handler() {
				this.onResize();
			},
			deep: true
		}
	},
	beforeDestroy() {
		if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null;
	},
	created() {
		//this.load();
		//this.loadNRLT();
	},
	mounted() {},
	updated() {
		console.warn('TAB: ' + this.tab);
		this.loadNRLT();
	},
	methods: {
		onResize() {
			if (this.chart) {
				this.chart.resize();
			}
		}, //end onResize

		async loadNRLT() {
			var parameters = this.$store.getters.getParameters;
			console.log(parameters);

			parameters.forEach((item) => {
				console.log('Lloron');
				console.log(item.acronym);

				const promise = realTimeByPollutant(item.acronym);
				promise.then((dt) => {
					console.log(dt);
				});
			});
		},

		load() {
			var parameters = this.$store.getters.getParameters;

			this.pollutants = [];
			this.pollutants.push({
				text: this.$t('panels.device.chooseaPollutant'),
				value: '0'
			});

			parameters.forEach((item) => {
				this.pollutants.push({
					text: item.name,
					value: item.acronym
				});
			});
		},

		getPollutant(evt) {
			if (evt != 0) {
				console.log('Cambia el evento');
				console.log(evt);
				this.pollutant = evt;
				this.pollutantNoSelected = false;
				this.form.chooseDate = '0';
				this.showChartOptions = false;
				//Fill the combo's date
				this.filter_options = [];
				this.filter_options.push({
					text: this.$t('panels.device.chooseaDate'),
					value: '0'
				});
				this.pollutantsByDate.forEach((item) => {
					if (item['pollutant'] == evt) {
						item['date'].forEach((dt) => {
							var date = {
								text: dt,
								value: dt
							};
							this.filter_options.push(date);
						});
					}
				});

				this.retrieveChartForPollutant(evt);
			} else {
				this.$puiNotify.info(this.$t('panels.device.noValidPollutant'));
				this.pollutantNoSelected = true;
				this.form.chooseDate = '0';
				this.showChartOptions = false;
			}
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
		}, //end getPollutant

		async retrieveChartForPollutant(pollutant) {
			console.log('retrieveChartForPollutant');
			console.log(pollutant);

			const filterPollutant = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'acronym', op: 'eq', data: pollutant } /*,
					{ field: 'idiom', op: 'eq', data: session.language }*/
				]
			};

			const body = {
				model: 'pmparameter',
				filter: filterPollutant
			};

			const { data } = await search(body);
			var parameter = data.data[0];
			console.log('Ejemplo de configuración asociada al parametro');
			console.log(parameter);
			this.parameterConfiguration = JSON.parse(parameter.chartconfiguration);
			console.log(this.parameterConfiguration);

			this.chartType = this.parameterConfiguration['series'][0]['type'];
			//console.log(this.chartType);

			// Este array contendra las  configuraciones disponibles a aplicar. La primera será la que se esta aplicando por defecto.
			this.configurations = [];
			this.configurations.push({
				text: parameter.chartname,
				value: JSON.parse(parameter.chartconfiguration)
			});
			//console.log(this.configurations);
		} //end retrieveChartForPollutant)
	}
};
</script>

<style scoped>
.topRight {
	/*position: absolute;*/
	top: 0px;
	right: 10px;
}

.topLeft {
	/*position: absolute;*/
	top: 0px;
	left: 10px;
}

.topOptions {
	display: flex;
	flex: auto;
	justify-content: space-between;
	align-content: center;
}
</style>
