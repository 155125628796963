<template>
	<div class="maptimeclock rounded">{{ localTime }}</div>
</template>

<script>
export default {
	name: 'Maptimeclock',
	data() {
		return {
			now: new Date(),
			timer: null,
			utcOffset: this.$store.getters.getUserProperties.utcoffset,
			dstOffset: this.$store.getters.getUserProperties.dstoffset,
			usesDST: this.$store.getters.getUserProperties.usesdst,
			insideDST: false
		};
	},
	created() {
		this.dstPeriod();
		this.timer = setInterval(() => {
			this.now = new Date();
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
	computed: {
		localTime() {
			let time = new Date(Date.UTC(
				this.now.getUTCFullYear(),
				this.now.getUTCMonth(),
				this.now.getUTCDate(),
				this.now.getUTCHours(),
				this.now.getUTCMinutes(),
				this.now.getUTCSeconds()
			));

			let offset = this.usesDST == 1 && this.insideDST ? this.dstOffset : this.utcOffset;
			if (this.utcOffset == null) {
				return this.now;
			} else {
				return this.offset(time, offset) + " (GMT" + offset + ")";
			}
		}
	},
	methods: {
		dstPeriod() {
			const year = this.now.getFullYear();
			this.March = this.getLastSunday(year, 2);
			this.October = this.getLastSunday(year, 9);
			this.insideDST = this.now > this.March && this.now < this.October;
		},
		getLastSunday(year, month) {
			let date = new Date(year, month + 1, 0);
			const day = date.getDay();
			const offset = day === 0 ? 0 : day;
			date.setDate(date.getDate() - offset);
			return date;
		},
		offset(time, offSet) {
			const sign = offSet[0] === '+' ? 1 : -1;
			const [hours, minutes] = offSet.slice(1).split(':').map(Number);

			const offsetInMilliseconds = sign * ((hours * 60 + minutes) * 60 * 1000);
			const currentTime = new Date(time.getTime() + offsetInMilliseconds);
			return currentTime.toUTCString().split(' ')[4]
		}
	},
};
</script>

<style scoped>
.maptimeclock {
	position: absolute;
	top: 1rem;
	z-index: 2;
	left: 3.5rem;
	background-color: black;
	color: white;
	opacity: 0.9 !important;
	padding: 4px;
}
</style>
