<template>
	<compare-aqi-chart :deviceObject="deviceObject" :formData="compareAqiFormData" :displayLocation="displayLocation" />
</template>

<script>
import CompareAqiChart from '@/components/echart/CompareAQIChart.vue';
//import { lastData } from '@/api/databases_API';
// eslint-disable-next-line no-unused-vars
import { search } from '@/api/common';
//import { query_FirstDataByParameter } from '@/utils/queries';

export default {
	name: 'DeviceCompareAqi',
	components: {
		CompareAqiChart
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			tab: 0,
			infoStartDate: this.startDateDisabled ? this.$t('aqi.infoDateIntervalDisabled') : this.$t('aqi.infoStartDate'),
			infoEndDate: this.endDateDisabled ? this.$t('aqi.infoDateIntervalDisabled') : this.$t('aqi.infoEndDate'),
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		compareAqiElasticData() {
			return this.$store.getters.getCompareAqiElasticData;
		},
		compareAqiFormData() {
			return this.$store.getters.getCompareAqiStore;
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		}
	},
	watch: {},
	created() {
		console.warn('CompareAqiStore', this.$store.getters.getCompareAqiStore);
		if (this.displayLocation === 'card') {
			this.setupHistoricStore();
		}
	},
	mounted() {
		console.warn('mounted device Compare Aqi', this.deviceObject, this.pollutants);
		//this.getFeaturePollutants();

		var d = new Date();
		d.setDate(d.getDate() - 2);
		console.warn(d);
		console.warn(d.valueOf());

		/* const promise = lastData(
			obtainIndexName(this.deviceObject),
			query_FirstDataByParameter(this.deviceObject.code, this.pollutants[0].elasticacronym)
		);
		promise.then((elasticData) => {
			console.warn('Resultado HISTORIC ' + this.pollutants[0].elasticacronym + ' ' + this.deviceObject.code, elasticData);
			console.log('QUERY: ', query_FirstDataByParameter(this.deviceObject.code, this.pollutants[0].elasticacronym));

			if (typeof elasticData.message[0] == 'object') {
				let timestamp = elasticData.message[0]['_source']['dateObserved_timestamp'];
				let fecha = new Date(timestamp);
				console.log('date obtained', timestamp, fecha);
			}
		}); */
	},
	updated() {
		console.warn('TAB: ' + this.tab, this.selectedTabHistoric);
		console.warn(this.historicElasticData);
	},
	methods: {
		async getFeaturePollutants() {
			var userProperties = this.userProperties;
			const filterFeature = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'lang', op: 'eq', data: userProperties.language },
					{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
					{ field: 'pmdatasourcetypeid', op: 'eq', data: this.deviceObject.pmdatasourcetypeid },
					{ field: 'disabled', op: 'eq', data: 0 }
				]
			};

			const body = {
				model: 'vluppmparameterdatasourcetype',
				filter: filterFeature
			};

			const { data } = await search(body);
			console.log(data.data);

			//this.$store.commit('setParameters', data.data);
		},
		setupHistoricStore() {
			console.warn('setupHistoricStore', this.$store.getters.getHistoricStore, this.$store.getters.getHistoricStore.length);
			if (!this.$store.getters.getCompareAqiStore) {
				this.$store.commit('setFormCompareAqiObject', {
					dateInterval: null,
					startDate: null,
					endDate: null,
					formDisabled: false,
					startDateDisabled: true,
					endDateDisabled: true,
					infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
					infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
					designOption: null,
					configurations: [],
					parameterConfiguration: {}
				});
			}
			console.log('historicStore', this.$store.getters.getCompareAqiStore);
		}
	}
};
</script>

<style lang="postcss">
.switchoff {
	color: var(--gris-300);
	& .switchoff_icon {
		font-size: 2rem;
	}
	& .switchoff-text {
		font-size: 1rem;
	}
}
</style>
