<template>
	<v-card flat tile class="d-flex flex-column justify-space-between align-center height-100 border pa-0" style="width: 76px">
		<span class="fw-500">{{ hourData.time }}</span>
		<div class="width-100 d-flex flex-column align-center">
			<v-img :src="hourData.condition.icon" contain max-height="52" width="52"></v-img>
			<span class="fs-regular fw-500">{{ hourData.temp_c }}º</span>
		</div>
		<div class="width-100">
			<div class="width-100 d-flex flex-column align-center border-t">
				<span>{{ hourData.precip_mm }} mm</span>
				<span class="width-100 text-center border-b">{{ hourData.humidity }}%</span>
			</div>
			<div class="width-100 d-flex flex-column align-center py-2">
				<i class="fa fa-long-arrow-up fs-lg fw-400" :style="{ rotate: hourData.wind_degree + 'deg' }"></i>
				<span class="fw-500">{{ hourData.wind_kph }} km/h</span>
			</div>
		</div>
	</v-card>
</template>

<script>
export default {
	props: {
		hourData: {
			type: Object,
			required: true
		}
	}
};
</script>

<style></style>
