<template>
	<div class="pt-0 width-100 advanced-chart">
		<v-form v-model="pollutantsAdvancedForm" ref="form" style="overflow: hidden auto">
			<v-row no-gutters class="px-3">
				<v-col cols="5" :md="form.option ? 5 : 4">
					<v-select
						v-model="form.selectedOptions"
						:items="options"
						:label="$t('adchart.selectPollutants')"
						item-text="label"
						item-value="id"
						multiple
						small-chips
						chips
						clearable
						ref="advancedSelect"
						style="margin-top: 10px"
						@input="inputSelectedOptions"
					></v-select>
				</v-col>
				<v-col cols="7" :md="form.option ? 7 : 8">
					<v-row no-gutters>
						<v-col>
							<pui-date-field
								:label="$t('aqi.startDate')"
								:disabled="isDatePickerDisabled"
								toplabel
								v-model="form.startDate"
								:min="minDate"
								:max="form.endDate ?? Date.now()"
								no-title
								@input="inputStartDate"
							></pui-date-field>
						</v-col>
						<v-col>
							<pui-date-field
								:label="$t('aqi.endDate')"
								:disabled="isEndDatePickerDisabled"
								toplabel
								v-model="form.endDate"
								:min="form.startDate"
								:max="Date.now()"
								no-title
								@input="submitEndDate"
							></pui-date-field>
						</v-col>
						<echart-export-excel
							v-if="form.option"
							:option="form.option"
							:designOption="form.designOption"
							:dateInterval="[form.startDate, form.endDate]"
							:bgColor="primaryColor"
							iconColor="white"
							height="39"
							spacingClass="mt-5"
							:chartInstance="chartInstance"
						></echart-export-excel>
					</v-row>
				</v-col>
				<!-- <v-col cols="6" :md="form.option ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.startDate')"
						:disabled="isDatePickerDisabled"
						toplabel
						v-model="form.startDate"
						:min="minDate"
						:max="form.endDate ?? Date.now()"
						no-title
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col cols="6" :md="form.option ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.endDate')"
						:disabled="isEndDatePickerDisabled"
						toplabel
						v-model="form.endDate"
						:min="form.startDate"
						:max="Date.now()"
						no-title
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
				<v-col cols="1" class="d-flex justify-start align-center"> </v-col> -->
			</v-row>
		</v-form>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation && !form.option" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<v-chart v-if="form.option" :loading="loadingAnimation" :option="form.option" :style="chartStyle" autoresize ref="advancedChart"></v-chart>
	</div>
</template>

<script>
//import * as echarts from 'echarts';
import { lastData /* getIndexFromElastic */ } from '@/api/databases_API';
import { query_filterParameterByInterval /* query_filterByInterval */ } from '@/utils/queries';
import { obtainIndexName, getDateByFormat } from '@/api/common';

import constants from '@/utils/constants';
import { query_FirstDataByDevice } from '../../utils/queries';
import VChart from 'vue-echarts';
import EchartExportExcel from './functionalities/EchartExportExcel.vue';

import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { LegendComponent, GridComponent, ToolboxComponent, DataZoomComponent, TooltipComponent, MarkLineComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([LegendComponent, GridComponent, ToolboxComponent, DataZoomComponent, TooltipComponent, MarkLineComponent, LineChart, CanvasRenderer]);
export default {
	name: 'AdvancedChart',
	components: {
		VChart,
		EchartExportExcel
	},
	props: {
		pollutants: {
			type: Array,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		},
		deviceObject: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			pollutantObjList: {},
			options: [],
			width: '100%',
			height: '100%',
			form: {
				startDate: null,
				endDate: null,
				selectedOptions: [],
				option: null,
				chartData: null,
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'ADVANCED_CHART'
				}
			},
			pollutantsAdvancedForm: false,
			loadingAnimation: false,
			chartStyle: {},
			chartType: 'line',
			primaryColor: this.$store.state.global.primaryColor,
			minDate: null,
			chartInstance: null
		};
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		isDatePickerDisabled() {
			return this.form.selectedOptions && this.form.selectedOptions.length == 0;
		},
		isEndDatePickerDisabled() {
			return this.form.startDate == null;
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		}
	},
	watch: {
		displayMode: {
			handler(newValue, oldValue) {
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
					if (this.form.chartData) {
						console.log('call changevisualization: displayMode', this.chart, this.form.designOption);

						if (!this.form.option) this.form.option = this.initChart();
					}
				}
			}
		},
		'form.option': {
			handler(newVal) {
				if (newVal) {
					this.$nextTick(() => {
						if (this.$refs['advancedChart']) {
							this.chartInstance = this.$refs['advancedChart'];
							console.log('Chart instance initialized:', this.chartInstance);
						} else {
							console.error('Chart instance is not available');
						}
					});
				}
			},
			immediate: true
		},
		deviceObject() {
			console.log('advanced watch 1', this.pollutantObjList);
			this.form = {
				startDate: null,
				endDate: null,
				selectedOptions: [],
				option: null,
				chartData: null,
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'ADVANCED_CHART'
				}
			};
			this.fillPollutants();
		}
	},
	mounted() {
		this.fillPollutants();
		this.getDeviceFirstDate();
		this.chartStyle = this.displayLocation == 'card' ? { height: '250px', width: '100%' } : { height: 'calc(70vh - 187px)', width: '85vw' };
		if (this.displayLocation == 'dialog') this.restoreChanges();
	},
	beforeDestroy() {
		this.deleteChart();
	},
	methods: {
		deleteChart() {
			this.form.option = null;
		},
		getAdvancedHistoricPollutantData(pollutant) {
			let pollutantData = [];
			let data = this.$store.getters.getAdvancedHistoricElasticData[pollutant];
			data.forEach((doc) => {
				pollutantData.push({
					date: getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants),
					value: parseFloat(doc[pollutant].toFixed(2))
				});
			});
			return pollutantData;
		},
		fillPollutants() {
			this.options = [];
			this.pollutants.forEach((pollutant) => {
				this.pollutantObjList[pollutant.elasticacronym] = pollutant;
				this.options.push({
					id: pollutant.elasticacronym,
					label: pollutant.name
				});
			});
			console.log(
				'advanced watch 2',
				this.pollutants,
				this.pollutantObjList,
				this.$store.getters.getParameters,
				this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid)
			);
			this.options.sort((a, b) => {
				const labelA = a.label.toUpperCase();
				const labelB = b.label.toUpperCase();

				if (labelA < labelB) {
					return -1;
				}
				if (labelA > labelB) {
					return 1;
				}
				return 0;
			});
		},
		getAllPollutantValues() {
			this.loadingAnimation = true;
			let indexName = obtainIndexName(this.deviceObject);

			let sDate = new Date(this.form.startDate);
			let eDate = new Date(this.form.endDate);
			let startPlusOne = sDate.getUTCDate() + 1;
			let endPlusOne = eDate.getUTCDate() + 1;
			sDate.setUTCHours(0, 0, 0, 0);
			sDate.setUTCDate(startPlusOne);
			eDate.setUTCHours(23, 59, 59, 0);
			eDate.setUTCDate(endPlusOne);
			let parameterPromises = [];
			let elasticDataObj = {};
			this.form.selectedOptions.forEach((parameter) => {
				parameterPromises.push(
					lastData(indexName, query_filterParameterByInterval(this.deviceObject.code, parameter, sDate.valueOf(), eDate.valueOf()))
				);
			});
			Promise.all(parameterPromises).then((results) => {
				if (results.length > 0 && results[0].message.length > 0) {
					this.form.selectedOptions.forEach((parameter, i) => {
						elasticDataObj[parameter] = results[i].message.map((element) => {
							return { [parameter]: element['_source'][parameter], dateObserved_timestamp: element['_source'].dateObserved_timestamp };
						});
					});
					this.form.chartData = elasticDataObj;
					this.$store.commit('setAdvancedHistoricElasticData', elasticDataObj);
					this.form.option = this.initChart();
					this.loadingAnimation = false;
					this.commitChanges();
				} else {
					this.loadingAnimation = false;
					this.form.chartData = null;
					this.$store.commit('setAdvancedHistoricElasticData', null);
					this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
				}
			});
		},

		commitChanges() {
			console.log('commit changes', this.displayMode, '?', this.displayMode == this.displayLocation);
			if (this.displayMode == this.displayLocation) {
				this.$store.commit('setFormAdvancedObject', this.form);
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				this.$store.getters.getAdvancedStore
					? (this.form = this.$store.getters.getAdvancedStore)
					: console.error('Note: Problem with advanced store', this.$store.getters.getAdvancedStore);
				//this.form.option = this.initChart();
				this.loadingAnimation = false;
				if (!this.form.chartData || !this.form.option) this.deleteChart();
			}
		},
		initChart() {
			let option = {
				toolbox: {
					right: 10,
					feature: {
						restore: {
							title: this.$t('charts.restore'),
							show: 'true'
						},
						saveAsImage: {
							title: this.$t('charts.saveAsImage'),
							show: 'true'
						},
						dataView: {
							title: this.$t('charts.title'),
							lang: [this.$t('charts.dataView'), this.$t('charts.closeView'), this.$t('charts.updateData')],
							show: 'true',
							readOnly: 'true'
						}
					}
				},
				dataZoom: [
					{
						type: 'slider',
						xAxisIndex: 0,
						filterMode: 'true'
					},
					{
						type: 'slider',
						yAxisIndex: 0,
						filterMode: 'true'
					},
					{
						type: 'inside',
						xAxisIndex: 0,
						filterMode: 'none'
					},
					{
						type: 'inside',
						yAxisIndex: 0,
						filterMode: 'none'
					}
				],
				yAxis: [{ type: 'value' }],
				tooltip: {
					trigger: 'axis'
				},
				legend: {}
			};
			console.log('init chart debug', this.form.chartData, this.$store.getters.getAdvancedHistoricElasticData);
			let elasticData = this.form.chartData ?? this.$store.getters.getAdvancedHistoricElasticData;

			//let axis = 0;
			let series = [];
			//let xAxis = [];

			let days = {};
			let values = {};
			let allDates = [];

			this.form.selectedOptions.forEach((pollutant) => {
				days[pollutant] = [];
				values[pollutant] = [];

				if (elasticData) {
					elasticData[pollutant].forEach((doc) => {
						let date = getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants);
						if (!allDates.includes(date)) allDates.push(date);
						days[pollutant].push(date);
						values[pollutant].push(parseFloat(doc[pollutant].toFixed(2)));
					});
				}
			});
			allDates.sort((a, b) => {
				return a.localeCompare(b);
			});
			console.log('debug days and values 1', days, values, allDates);
			this.form.selectedOptions.forEach((pollutant) => {
				if (allDates.length != days[pollutant].length) {
					allDates.forEach((date, i) => {
						if (!days[pollutant].includes(date)) {
							days[pollutant] = days[pollutant].toSpliced(i, 0, date);
							values[pollutant] = values[pollutant].toSpliced(i, 0, null);
						}
					});
				}
			});
			console.log('debug days and values 2', days, values, allDates, this.pollutantObjList);

			this.form.selectedOptions.forEach((pollutant) => {
				let pollutantObj = this.pollutantObjList[pollutant];
				let pollutantTitle =
					pollutantObj.symbol && pollutantObj.symbol.trim().length > 0
						? pollutantObj.name + ' (' + pollutantObj.symbol + ')'
						: pollutantObj.name;
				series.push({
					name: pollutantTitle,
					type: 'line',
					//xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: pollutantObj.symbol ? '{@score}' + pollutantObj.symbol : '{@score}'
					},
					data: values[pollutant]
				});
				/* axis++;

				xAxis.push({
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						onZero: false
					},
					data: days
				}); */
			});
			option['xAxis'] = {
				type: 'category',
				show: true,
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: allDates
			};

			option['series'] = series;
			//option['xAxis'] = xAxis;

			console.log('option log', JSON.stringify(option));
			return option;
		},
		inputStartDate() {
			console.debug('debug input start', this.form.startDate, this.form.endDate);
			if (this.form.startDate) {
				this.form.endDateDisabled = false;
				if (this.form.endDate) this.submitEndDate();
				else this.commitChanges();
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
		},
		submitEndDate() {
			if (this.form.startDate && this.form.endDate) this.getAllPollutantValues();
		},
		inputSelectedOptions() {
			console.log('input option', this.displayMode);
			this.commitChanges();
			if (this.form.selectedOptions.length == 2) {
				const advancedSelect = this.$refs.advancedSelect;
				if (advancedSelect) {
					advancedSelect.blur();
				}
			}
			if (this.form.startDate && this.form.endDate && this.form.selectedOptions.length == 2) {
				this.getAllPollutantValues();
			}
			if (this.form.selectedOptions.length > 2) {
				this.form.selectedOptions.pop();
				this.$puiNotify.info(this.$t('adchart.maxNumberReached'));
			}
		},
		async getDeviceFirstDate() {
			let index = obtainIndexName(this.deviceObject);
			let result = await lastData(index, query_FirstDataByDevice(this.deviceObject.code));
			if (typeof result.message[0] !== 'undefined') {
				let messageData = result.message[0]._source;
				this.minDate = new Date(messageData['dateObserved_timestamp']).toISOString();
			}
		}
	}
};
</script>

<style scoped></style>
