<template>
	<div class="pt-0 w-100" style="overflow: hidden auto">
		<v-form v-model="pollutantsForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<div row wrap>
				<v-layout wrap class="px-1">
					<v-flex xs12 md4 class="px-1">
						<v-select
							:label="$t('aqi.dateInterval')"
							toplabel
							clearable
							required
							:disabled="form.formDisabled"
							v-model="form.dateInterval"
							:items="intervals"
							@input="submitDateInterval"
							class="form-control-select mt-2"
						></v-select>
					</v-flex>
					<v-flex
						xs12
						:md4="!form.showChartOptions || displayLocation != 'card'"
						:md3="form.showChartOptions && displayLocation == 'card'"
						class="px-1"
					>
						<pui-date-field
							:label="$t('aqi.startDate')"
							toplabel
							:required="!form.startDateDisabled"
							v-model="form.startDate"
							:disabled="form.startDateDisabled"
							:min="getFirstDateParameter()"
							:max="getLastDateParameter() ?? Date.now()"
							:tooltipDescription="form.infoStartDate"
							@input="inputStartDate"
						></pui-date-field>
					</v-flex>
					<v-flex
						xs12
						:md4="!form.showChartOptions || displayLocation != 'card'"
						:md3="form.showChartOptions && displayLocation == 'card'"
						class="px-1"
					>
						<pui-date-field
							:label="$t('aqi.endDate')"
							toplabel
							:required="!form.endDateDisabled"
							v-model="form.endDate"
							:disabled="form.endDateDisabled"
							:min="form.startDate"
							:max="getLastDateParameter() ?? Date.now()"
							:tooltipDescription="form.infoEndDate"
							@input="submitEndDate"
						></pui-date-field>
					</v-flex>
					<v-flex xs12 md2 v-if="form.showChartOptions && displayLocation == 'card'" class="d-flex align-center justify-center px-3">
						<!-- <span class="mx-2" @click="switchMetrics">{{ $t('panels.device.showMetrics') }}</span> -->
						<v-btn
							:disabled="form.formDisabled"
							depressed
							color="bg-color-primary color-white width-100"
							class="mb-3"
							v-model="toggleMetrics"
							@click="toggleDisplayMode"
							>{{ $t('panels.device.showMetrics') }} <v-icon class="fak fa-solid-open pl-2 color-white"></v-icon
						></v-btn>
					</v-flex>
				</v-layout>
			</div>
		</v-form>
		<!-- metrics component -->
		<div row nowrap v-if="displayLocation == 'dialog' && form.showMetrics == true" class="justify-center">
			<v-layout nowrap>
				<v-col class="d-flex justify-center pa-1">
					<v-chip class="card-panel fw-bold" :ripple="false">MAE: {{ form.minMAE }}</v-chip>
					<!-- <v-chip class="fw-bold pa-0 ma-0">MAE: {{ minMAE }}</v-chip> -->
				</v-col>

				<v-col class="d-flex justify-center pa-1">
					<v-chip class="fw-bold ma-0">MAPE: {{ form.minMAPE }}</v-chip>

					<!--<count-to :start-val="0" :end-val="81212" :duration="3000" class="card-panel-num" />-->
				</v-col>

				<v-col class="d-flex justify-center pa-1">
					<v-chip class="fw-bold ma-0">MSE: {{ form.minMSE }}</v-chip>
				</v-col>

				<v-col class="d-flex justify-center pa-1">
					<v-chip class="fw-bold ma-0">R&sup2;: {{ form.minR2 }}</v-chip>
				</v-col>

				<v-col class="d-flex justify-center pa-1">
					<v-chip class="fw-bold ma-0">MAE: {{ form.maxMAE }}</v-chip>
				</v-col>

				<v-col class="d-flex justify-center pa-1">
					<v-chip class="fw-bold ma-0">MAPE: {{ form.maxMAPE }}</v-chip>
				</v-col>

				<v-col class="d-flex justify-center pa-1">
					<v-chip class="fw-bold ma-0">MSE: {{ form.maxMSE }}</v-chip>
				</v-col>

				<v-col class="d-flex justify-center pa-1">
					<v-chip class="fw-bold ma-0">R&sup2;: {{ form.maxR2 }}</v-chip>
				</v-col>
			</v-layout>
		</div>
		<div row nowrap v-else-if="displayLocation == 'dialog'" style="height: 40px"></div>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>

		<!--  echarts component -->
		<div ref="echart" class="d-flex justify-center align-center" v-show="!loadingAnimation" :style="chartStyle" />
	</div>
</template>

<script>
import * as echarts from 'echarts';

import { search, filterByParameter, ChartOptions, obtainIndexName, filterString, fillValues, averageForNullValues } from '@/api/common';

// eslint-disable-next-line no-unused-vars
import { obtainMetrics } from '@/api/metrics';

import { lastData } from '@/api/databases_API';

import { waterportsquery_DataByWaterPollutant } from '@/utils/waterports_queries';
import { CARD_HEIGHT } from '@/lib/variables/panels';

import constants from '@/utils/constants';

export default {
	name: 'WaterportsPredictionChart',
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],

			form: {
				index: this.pollutant.elasticacronym,
				dateInterval: null,
				startDate: null,
				endDate: null,
				designOption: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				chartData: [],
				parameterConfiguration: {},
				chartType: '',
				configurations: [],
				showChartOptions: false,
				historicalChartData: {},
				showMetrics: false,
				minMAE: '',
				minMAPE: '',
				minMSE: '',
				minR2: '',
				maxMAE: '',
				maxMAPE: '',
				maxMSE: '',
				maxR2: ''
			},
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],
			chartStyle: {},
			pollutantsForm: false,
			loadingAnimation: false,
			toggleMetrics: false,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	components: {},
	props: {
		feature: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
		/* chartData: {
			type: Array,
			required: true
		} */
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters;
		},
		predictionElasticData() {
			return this.$store.getters.getPredictionElasticData[this.pollutant.elasticacronym];
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		},
		formPrediction() {
			return this.$store.getters.getPredictionStore[this.form.index];
		},
		predictionFirstDates() {
			return this.$store.getters.getPredictionFirstDates;
		},
		predictionLastDates() {
			return this.$store.getters.getPredictionLastDates;
		},
		selectedTabPrediction: {
			get() {
				return this.$store.getters.getSelectedTabPrediction;
			},
			set(value) {
				this.$store.commit('setSelectedTabPrediction', value);
			}
		}
	},

	watch: {
		selectedTabPrediction: {
			handler(newValue, oldValue) {
				console.log('info: tab prediction ' + this.form.index + ' from', oldValue, 'to', newValue);
				setTimeout(this.resizeChart, 200);
			}
		},
		displayMode: {
			handler(newValue, oldValue) {
				console.warn('displayMode changed from', oldValue, 'to', newValue);
				if (newValue == 'card') {
					this.toggleMetrics = false;
				} else {
					this.toggleMetrics = true;
				}
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
					if (this.form.chartData && typeof this.form.chartData.message === 'object') {
						console.warn(this.displayLocation + ' init chart in new visualization', this.chart);
						this.form.showChartOptions = true;
						this.initChart(this.pollutant.acronym, this.pollutant.symbol, this.form.chartData, this.pollutant.elasticacronym);
					} else {
						this.deleteChart();
					}
				}
			}
		},
		form: {
			handler(newFormData, oldFormData) {
				console.warn(this.displayLocation + ' form data changed from', oldFormData, 'to', newFormData, this.displayMode);
				console.log('boolean form dateinterval', newFormData.dateInterval, newFormData.dateInterval == oldFormData.dateInterval);
				//console.warn('commiting...', { index: this.form.index, data: newFormData });
				//this.$store.commit('setFormHistoricObject', { index: this.form.index, data: newFormData });

				console.log(
					'PredictionStore getter',
					this.$store.getters.getPredictionStore,
					this.$store.getters.getPredictionStore[this.form.index]
				);
				if (this.displayMode === 'dialog') {
					console.warn(
						'dialog height',
						document.getElementById('dialog-container'),
						document.getElementById('dialog-container').clientHeight
					);
				}
			}
		},
		// eslint-disable-next-line no-unused-vars
		'form.designOption': function (newValue, oldValue) {
			console.warn('debug design option from: ', oldValue, ' to: ', newValue);
			console.warn(newValue);
		}
	},
	created() {
		//this.configurationByPollutant();
		console.log('===== created waterportsChart ' + this.displayLocation, this.form);
	},
	mounted() {
		this.chartStyle = this.displayLocation == 'card' ? { height: '250px', width: '100%' } : { height: 'calc(70vh - 227px)', width: '85vw' };
		if (this.displayLocation == 'dialog') {
			this.restoreChanges();
		}
		if (this.form.chartData && typeof this.form.chartData.message === 'object') {
			console.warn(this.displayLocation + ' init chart in new visualization', this.chart);
			this.form.showChartOptions = true;
			this.initChart(this.pollutant.acronym, this.pollutant.symbol, this.form.chartData, this.pollutant.elasticacronym);
		} else {
			this.deleteChart();
		}
	},
	updated() {},
	beforeDestroy() {
		this.deleteChart();
	},
	methods: {
		deleteChart() {
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
			window.removeEventListener('resize', this.resizeChart);
			this.form.showMetrics = false;
		},
		resizeChart() {
			console.log('info: resize chart ' + this.form.index);
			if (this.chart) {
				// Resize chart
				this.chart.resize();
			}
		},

		// eslint-disable-next-line no-unused-vars
		initChart(pollutantAcronym, pollutantSymbol, historicPollutantData, elasticacronym) {
			this.loadingAnimation = false;
			this.form.showChartOptions = true;
			this.deleteChart();

			var pollutantRealMinValues = [];
			var pollutantRealMaxValues = [];
			var pollutantRealDays = [];

			var pollutantForecastMinValues = [];
			var pollutantForecastMaxValues = [];
			var pollutantForecastDays = [];
			var pollutantForecastWindow = [];

			var endForecastMinValues = [];
			var endForecastMaxValues = [];
			var endForecastDays = [];
			var endForecastWindow = [];

			console.warn('historicPollutantData');
			console.warn(this.form.predictionElasticData);

			var allDateData = [];

			historicPollutantData.message.forEach((item) => {
				allDateData.push(item._source['date']);

				pollutantRealDays.push(item._source['date']);
				pollutantRealMinValues.push(item._source['min']);
				pollutantRealMaxValues.push(item._source['max']);

				item._source['predictions'].forEach((forecast) => {
					//console.log(forecast);
					pollutantForecastDays.push(forecast['date']);
					pollutantForecastMinValues.push(forecast['min']);
					pollutantForecastMaxValues.push(forecast['max']);
					pollutantForecastWindow.push(forecast['window']);

					allDateData.push(forecast['date']);
				});
			});

			for (let i = 0; i < pollutantForecastDays.length; i++) {
				console.log('itero sobre los registros');
				if (endForecastDays.length == 0) {
					//primer registro lo meto siempre
					endForecastDays.push(pollutantForecastDays[i]);
					endForecastMinValues.push(pollutantForecastMinValues[i]);
					endForecastMaxValues.push(pollutantForecastMaxValues[i]);
					endForecastWindow.push(pollutantForecastWindow[i]);
				} else {
					//compruebo si el registro ya esta en el array antes de meterlo
					if (!endForecastDays.includes(pollutantForecastDays[i])) {
						//Si no incluyo el elemento lo inserto
						endForecastDays.push(pollutantForecastDays[i]);
						endForecastMinValues.push(pollutantForecastMinValues[i]);
						endForecastMaxValues.push(pollutantForecastMaxValues[i]);
						endForecastWindow.push(pollutantForecastWindow[i]);
					} else {
						// Esta fecha ya existe en mi array. Me quedare con la que tenga menor window
						for (let j = 0; j < endForecastDays.length; j++) {
							if (endForecastDays[j] == pollutantForecastDays[i]) {
								// Misma fecha, comparo la window
								/*console.log(endForecastDays[j]);

								console.log(pollutantForecastWindow[i].split('H')[0]);
								console.log(pollutantForecastValues[i]);

								console.log(endForecastWindow[j].split('H')[0]);
								console.log(endForecastValues[j]);*/

								if (parseInt(pollutantForecastWindow[i].split('H')[0]) < parseInt(endForecastWindow[j].split('H')[0])) {
									//Reemplazo el valor
									endForecastMinValues[j] = pollutantForecastMinValues[i];
									endForecastMaxValues[j] = pollutantForecastMaxValues[i];
									endForecastWindow[j] = pollutantForecastWindow[i];
									break;
								}
							}
						}
					}
				}
			}

			//console.log('All dates -------------------------');
			var uniqueDates = filterString(allDateData);
			/*console.log(allDateData);*/
			console.log(uniqueDates);

			var uniqueRealMinValues = fillValues(pollutantRealDays, pollutantRealMinValues, uniqueDates);
			console.log('Unic real values (min) ------------------');
			console.log(uniqueRealMinValues);

			var uniqueForecastMinValues = fillValues(endForecastDays, endForecastMinValues, uniqueDates);
			console.log('Unic forecast values (min) ------------------');
			console.log(uniqueForecastMinValues);

			//Preparo vector validatedData (min)
			var validatedMinData = [];
			for (let r = 0; r < uniqueRealMinValues.length; r++) {
				//if (uniqueRealMinValues[r] !== null) {
				validatedMinData.push({
					value: uniqueRealMinValues[r],
					date: uniqueDates[r]
				});
				//}

				/*if (uniqueRealMinValues[r] === null) {
					validatedMinData[r]['value'] = 0;
				}*/
			}

			//Average value for the null values (Validated data (MIN))
			console.log('Complete null values with average');
			console.log('Original array: ', validatedMinData);
			console.log(averageForNullValues(validatedMinData));

			//Preparo vector predictedData (min)
			var predictedMinData = [];
			for (let r = 0; r < uniqueForecastMinValues.length; r++) {
				//if (uniqueForecastMinValues[r] !== null) {
				predictedMinData.push({
					value: uniqueForecastMinValues[r],
					date: uniqueDates[r]
				});
				//}

				/*if (uniqueForecastMinValues[r] === null) {
					predictedMinData[r]['value'] = 0;
				}*/
			}

			//Average value for the null values (Predicted data (MIN))
			console.log('Complete null values with average');
			console.log('Original array: ', predictedMinData);
			console.log(averageForNullValues(predictedMinData));

			/* -- MAX VALUES */

			var uniqueRealMaxValues = fillValues(pollutantRealDays, pollutantRealMaxValues, uniqueDates);
			console.log('Unic real values (max) ------------------');
			console.log(uniqueRealMaxValues);

			var uniqueForecastMaxValues = fillValues(endForecastDays, endForecastMaxValues, uniqueDates);
			console.log('Unic forecast values (max) ------------------');
			console.log(uniqueForecastMaxValues);

			//Preparo vector validatedData (max)
			var validatedMaxData = [];
			for (let r = 0; r < uniqueRealMaxValues.length; r++) {
				//if (uniqueRealMaxValues[r] !== null) {
				validatedMaxData.push({
					value: uniqueRealMaxValues[r],
					date: uniqueDates[r]
				});
				//}

				/*if (uniqueRealMaxValues[r] === null) {
					validatedMaxData[r]['value'] = 0;
				}*/
			}

			//Average value for the null values (Predicted data (MAX))
			console.log('Complete null values with average');
			console.log('Original array: ', validatedMaxData);
			console.log(averageForNullValues(validatedMaxData));

			//Preparo vector predictedData (max)
			var predictedMaxData = [];
			for (let r = 0; r < uniqueForecastMaxValues.length; r++) {
				//if (uniqueForecastMaxValues[r] !== null) {
				predictedMaxData.push({
					value: uniqueForecastMaxValues[r],
					date: uniqueDates[r]
				});
				//}

				/*if (uniqueForecastMaxValues[r] === null) {
					predictedMaxData[r]['value'] = 0;
				}*/
			}

			//Average value for the null values (Real data (MAX))
			console.log('Complete null values with average');
			console.log('Original array: ', predictedMaxData);
			console.log(averageForNullValues(predictedMaxData));

			// eslint-disable-next-line no-unused-vars
			var min_metrics = {
				validatedData: validatedMinData,
				predictedData: predictedMinData
			};

			const promise_MinValues = obtainMetrics(min_metrics);
			promise_MinValues.then((metric) => {
				console.log('Minimo: ', metric);
				this.form.minMAE = Number(metric.message.predictedData.mae).toFixed(3);
				this.form.minMAPE = Number(metric.message.predictedData.mape).toFixed(3);
				this.form.minMSE = Number(metric.message.predictedData.mse).toFixed(3);
				this.form.minR2 = Number(metric.message.predictedData.r2_v2).toFixed(3);
			});

			// eslint-disable-next-line no-unused-vars
			var max_metrics = {
				validatedData: validatedMaxData,
				predictedData: predictedMaxData
			};

			const promise_MaxValues = obtainMetrics(max_metrics);
			promise_MaxValues.then((metric) => {
				console.log('Máximo: ', metric);
				this.form.maxMAE = Number(metric.message.predictedData.mae).toFixed(3);
				this.form.maxMAPE = Number(metric.message.predictedData.mape).toFixed(3);
				this.form.maxMSE = Number(metric.message.predictedData.mse).toFixed(3);
				this.form.maxR2 = Number(metric.message.predictedData.r2_v2).toFixed(3);
			});

			this.form.showMetrics = true;

			console.log(pollutantSymbol, historicPollutantData);
			this.chart = echarts.init(this.$refs['echart']);
			window.addEventListener('resize', this.resizeChart);

			var option;

			option = {
				tooltip: {
					trigger: 'none',
					axisPointer: {
						type: 'cross'
					}
				},
				legend: {},
				grid: {
					top: 70,
					bottom: 50
				},
				xAxis: [
					{
						type: 'category',
						data: uniqueDates //pollutantRealDays
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				dataZoom: [
					{ type: 'slider', xAxisIndex: 0, filterMode: 'true' },
					{ type: 'slider', yAxisIndex: 0, filterMode: 'true' },
					{ type: 'inside', xAxisIndex: 0, filterMode: 'none' },
					{ type: 'inside', yAxisIndex: 0, filterMode: 'none' }
				],
				series: [
					{
						name: 'Real values (Min.)',
						type: 'line',
						smooth: true,
						emphasis: {
							focus: 'series'
						},
						data: uniqueRealMinValues //pollutantRealValues
						/*markPoint: {
							data: [
								{ type: 'max', name: 'Max' },
								{ type: 'min', name: 'Min' }
							]
						},
						markLine: {
							data: [{ type: 'average', name: 'Avg' }]
						}*/
					},
					{
						name: 'Forecast values (Min.)',
						type: 'line',
						smooth: true,
						emphasis: {
							focus: 'series'
						},
						data: uniqueForecastMinValues //endForecastValues
						/*markPoint: {
							data: [
								{ type: 'max', name: 'Max' },
								{ type: 'min', name: 'Min' }
							]
						},
						markLine: {
							data: [{ type: 'average', name: 'Avg' }]
						}*/
					},
					{
						name: 'Real values (Max.)',
						type: 'line',
						smooth: true,
						emphasis: {
							focus: 'series'
						},
						data: uniqueRealMaxValues //pollutantRealValues
						/*markPoint: {
							data: [
								{ type: 'max', name: 'Max' },
								{ type: 'min', name: 'Min' }
							]
						},
						markLine: {
							data: [{ type: 'average', name: 'Avg' }]
						}*/
					},
					{
						name: 'Forecast values (Max.)',
						type: 'line',
						smooth: true,
						emphasis: {
							focus: 'series'
						},
						data: uniqueForecastMaxValues //endForecastValues
						/*markPoint: {
							data: [
								{ type: 'max', name: 'Max' },
								{ type: 'min', name: 'Min' }
							]
						},
						markLine: {
							data: [{ type: 'average', name: 'Avg' }]
						}*/
					}
				]
			};

			/*option = this.parameterConfiguration;

			option['legend']['data'] = [pollutantAcronym];

			option['xAxis']['data'] = pollutantRealDays; // dateObserved

			option['series'] = [
				{
					name: pollutantAcronym,
					type: this.chartType,
					data: pollutantRealValues
				}
			];*/

			console.log(option);
			option && this.chart.setOption(option);
		},

		cleanMetrics() {
			this.form.minMAE = '';
			this.form.minMAPE = '';
			this.form.minMSE = '';
			this.form.minR2 = '';
			this.form.maxMAE = '';
			this.form.maxMAPE = '';
			this.form.maxMSE = '';
			this.form.maxR2 = '';
		},

		async configurationByPollutant() {
			console.log('configurationByPollutant --> WaterPortsChart');
			console.log(this.pollutant);
			const body = filterByParameter(this.pollutant.acronym);
			console.log(body);

			const { data } = await search(body);
			var parameter = data.data[0];
			console.log('Ejemplo de configuración asociada al parametro');
			console.log(parameter);
			this.form.parameterConfiguration = JSON.parse(parameter.chartconfiguration);
			console.log('configuration for chart');
			console.log(this.parameterConfiguration);

			this.chartType = this.form.parameterConfiguration['series'][0]['type'];
			console.log('Chart type: ', this.form.chartType);

			// Este array contendra las  configuraciones disponibles a aplicar. La primera será la que se esta aplicando por defecto.
			this.form.configurations = [];
			this.form.configurations.push({
				text: parameter.chartname,
				value: JSON.parse(parameter.chartconfiguration)
			});
			console.log('Array configuraciones: ', this.form.configurations);
		}, //end configurationByPollutant

		showDesignOptions() {
			this.form.showChartOptions = true;
			const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC);
			console.log(historicalChartOptions);
			const promise = search(historicalChartOptions);
			promise.then((historicalChartData) => {
				console.log('Other configurations available for the same tab (HISTORIC): ', historicalChartData);

				this.form.historicalChartData = historicalChartData.data.data;
				console.log(this.historicalChartData);
				var existConfiguration = false;
				this.form.historicalChartData.forEach((item) => {
					for (let i = 0; i < this.configurations.length; i++) {
						//console.log('ya tengo items');
						if (this.form.configurations[i].text == item['visualizationname']) {
							existConfiguration = true;
							break;
						} else {
							//se trata de un pollutant nuevo, continuo buscando
							existConfiguration = false;
						}
					}
					if (!existConfiguration) {
						//se trata de una configuración nueva
						this.form.configurations.push({
							text: item.visualizationname,
							value: JSON.parse(item.configuration)
						});
					}
				});
				console.log('Total of configurations available (1st one is the associated to the pollutant)');
				console.log(this.form.configurations);
				console.warn(this.form.designOption);

				//Establezco el primer item como seleccionado. La configuración por defecto
				this.form.designOption = this.form.configurations[0];
				console.warn(this.form.designOption);
			});
		}, //end showDesignOptions

		changeVisualization(design) {
			console.warn(design);
			if (design != 0) {
				console.log('Apply the new design');

				this.form.chartType = design['series'][0]['type'];
				console.log(this.form.chartType);

				this.initChart(this.pollutant.acronym, this.pollutant.symbol, this.predictionElasticData, this.pollutant.elasticacronym);
			}
		},
		submitDateInterval() {
			var d = new Date();
			if (this.form.dateInterval) {
				this.form.showMetrics = false;
				this.cleanMetrics();
				if (this.form.dateInterval == -1) {
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
					this.commitChanges();
				} else {
					this.loadingAnimation = true;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					d.setDate(d.getDate() - this.form.dateInterval);
					console.warn(d);
					console.warn(d.valueOf());
					this.deleteChart();
					this.form.chartData = {};
					/*this.$store.commit('setHistoricElasticData', {
						index: this.pollutant.elasticacronym,
						data: null
					});*/
					console.log(
						'info: date interval ' + this.form.dateInterval,
						obtainIndexName(this.feature),
						waterportsquery_DataByWaterPollutant(this.pollutant.elasticacronym, d.valueOf(), Date.now())
					);
					const promise = lastData(
						obtainIndexName(this.feature),
						waterportsquery_DataByWaterPollutant(this.pollutant.elasticacronym, d.valueOf(), Date.now())
					);
					promise.then((elasticData) => {
						console.warn('Waterports Result ' + this.pollutant.elasticacronym, elasticData);
						console.log(waterportsquery_DataByWaterPollutant(this.pollutant.elasticacronym, d.valueOf(), Date.now()));
						if (elasticData.message.length > 0) {
							this.$store.commit('setPredictionElasticData', {
								index: this.pollutant.elasticacronym,
								data: elasticData
							});
							this.form.chartData = elasticData;
							//this.showDesignOptions();
							this.initChart(this.pollutant.acronym, this.pollutant.symbol, elasticData, this.pollutant.elasticacronym);
							console.warn('loadingAnimation: ' + this.form.loadingAnimation);
						} else {
							console.log('inserted null');
							this.$store.commit('setPredictionElasticData', {
								index: this.pollutant.elasticacronym,
								data: null
							});
							this.loadingAnimation = false;
							this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
							this.form.showChartOptions = false;
						}
						this.commitChanges();
					});
				}
			} else {
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.deleteChart();
				this.form.showChartOptions = false;
				this.loadingAnimation = false;
				this.commitChanges();
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				console.warn('startDate: ' + this.form.startDate);
				console.warn('startDate: ' + Date.parse(this.form.startDate));

				this.form.endDateDisabled = false;
				if (this.form.endDate) {
					this.submitEndDate(); // better usability
				} else {
					this.commitChanges();
				}
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
			//this.form.endDate = null;
		},
		submitEndDate() {
			if (this.form.startDate && this.form.endDate) {
				this.deleteChart();
				this.loadingAnimation = true;
				this.form.chartData = {};

				/* this.$store.commit('setPredictionElasticData', {
					index: this.pollutant.elasticacronym,
					data: null
				}); */
				console.warn('loadingAnimation: ' + this.loadingAnimation);

				const promise = lastData(
					obtainIndexName(this.feature),
					waterportsquery_DataByWaterPollutant(
						this.pollutant.elasticacronym,
						Date.parse(this.form.startDate),
						Date.parse(this.form.endDate)
					)
				);

				promise.then((elasticData) => {
					console.warn('Resultado WATERPORTS ' + this.pollutant.elasticacronym, elasticData);
					console.log(
						waterportsquery_DataByWaterPollutant(
							this.pollutant.elasticacronym,
							Date.parse(this.form.startDate),
							Date.parse(this.form.endDate)
						)
					);
					if (elasticData.message.length > 0) {
						this.$store.commit('setPredictionElasticData', {
							index: this.pollutant.elasticacronym,
							data: elasticData
						});
						this.form.chartData = elasticData;
						//this.showDesignOptions();
						this.initChart(this.pollutant.acronym, this.pollutant.symbol, elasticData, this.pollutant.elasticacronym);
						console.warn('loadingAnimation: ' + this.loadingAnimation);
					} else {
						console.log('inserted null');
						this.$store.commit('setPredictionElasticData', {
							index: this.pollutant.elasticacronym,
							data: null
						});
						this.loadingAnimation = false;
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
						this.form.showChartOptions = false;
					}
					this.commitChanges();
				});
			}
		},
		commitChanges() {
			if (this.displayMode == this.displayLocation) {
				console.warn('log: commiting changes waterports ' + this.displayLocation + '...', { index: this.form.index, data: this.form });
				this.$store.commit('setFormPredictionObject', { index: this.form.index, data: this.form });
			} else {
				console.log('log: not commiting changes waterports ' + this.displayLocation);
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				console.warn('log: restoring changes waterports ' + this.displayLocation + '...', this.formHistoric, this.chart, this.form.chartData);
				this.form = this.$store.getters.getPredictionStore[this.form.index];
			} else {
				console.log('log: not restoring changes waterports ' + this.displayLocation);
			}
		},
		getFirstDateParameter() {
			//console.log('info: prediction first dates', this.predictionFirstDates, this.predictionFirstDates[this.pollutant.elasticacronym]);
			return this.predictionFirstDates[this.pollutant.elasticacronym] ? this.predictionFirstDates[this.pollutant.elasticacronym] : null;
		},
		getLastDateParameter() {
			//console.log('info: prediction last dates', this.predictionLastDates, this.predictionLastDates[this.pollutant.elasticacronym]);
			return this.predictionLastDates[this.pollutant.elasticacronym] ? this.predictionLastDates[this.pollutant.elasticacronym] : null;
		},
		switchMetrics() {
			this.showMetrics = !this.showMetrics;
		},
		toggleDisplayMode() {
			// Toggle between 'dialog' and 'card' display modes
			this.displayMode = this.displayMode === 'dialog' ? 'card' : 'dialog';
			switch (this.displayMode) {
				case 'card':
					document.documentElement.style.setProperty('--map-control-bottom-position', CARD_HEIGHT + 'px');
					break;
				case 'dialog':
					document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
					break;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.topOptions {
	display: flex;
	flex: auto;
	justify-content: space-between;
	align-content: center;
}

.card-panel {
	//height: 60px; //108px;
	//font-weight: normal !important;
	//font-size: 10px;
	//overflow: auto;
	color: e6e6e6;
	background: #e4e4e4;
	//box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
	border-color: rgba(0, 0, 0, 0.05);
}
</style>
