import { filterDependOnRoleAndDataSourceType } from '@/api/common';

export default {
	methods: {
		/**
		 * Body post petition to PuiSearch to get datasourceinstance list
		 * @returns Data source instance list
		 */
		getBodySearch(model, pmdatasourcetypeid) {
			const filterByOrganization = filterDependOnRoleAndDataSourceType(pmdatasourcetypeid, this.session);
			var body = {
				model: model,
				filter: filterByOrganization,
				page: 1,
				queryLang: 'es',
				queryText: 'es',
				rows: 9999 // Para obtener todos los elementos
			};
			return body;
		},

		/**
		 * Build object with own notifications layers list, methods and properties
		 * @param {pm_notifications} datasouceinstanceList
		 * @returns Layer object
		 */
		vizObj(typefeature, projection, model, active, datasourcetypeicon) {
			//const { mapicons: icons } = Icons;

			const iconProperties = {
				opacity: 1,
				scale: 1.5
			};

			const featureProperties = {
				type: typefeature,
				model: model,
				projection: projection,
				featureProjection: 'EPSG:3857',
				datasourcetypeicon: datasourcetypeicon
			};
			const layerProperties = {
				visibility: active
			};

			/* function getIconByName(name) {
				return icons[name];
			} */

			return {
				featureProperties,
				iconProperties,
				layerProperties
				/* getIconByName */
			};
		},

		getVizLayer(typefeature, projection, model, active, datasourcetypeicon, datasourcetypedisabled) {
			return {
				viz: this.vizObj(typefeature, projection, model, active, datasourcetypeicon, datasourcetypedisabled)
			};
		}
	}
};
