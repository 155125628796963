<template>
	<!-- <div :style="appearance"> -->
	<v-card class="overflow-hidden" style="width: 100%; height: 100%; overflow-y: scroll !important">
		<v-tabs v-model="tab" align-tabs="title">
			<v-tab v-for="item in notificationTabs" :key="item.value" :value="item.value">
				{{ $t(item.label) }}
			</v-tab>
		</v-tabs>

		<v-card-text class="pa-0 overflow-hidden ma-0 height-100" style="overflow-y: scroll !important">
			<v-window v-model="tab" style="overflow-y: scroll !important">
				<v-window-item v-for="item in notificationTabs" :key="item.value" :value="item.value" style="overflow-y: scroll !important">
					<v-card class="pa-0" flat style="overflow-y: scroll !important">
						<component :notificationObject="notificationObject" :is="item.component" :displayLocation="displayLocation" />
					</v-card>
				</v-window-item>
			</v-window>
		</v-card-text>
	</v-card>
	<!-- </div> -->
</template>

<script>
import NotificationHistoric from './NotificationHistoric.vue';
import NotificationActive from './NotificationActive.vue';

//import constants from '@/utils/constants';
import { obtainPanelRealTimeData } from '@/api/common';

export default {
	name: 'notificationPanel',
	components: {
		NotificationHistoric,
		NotificationActive
	},
	props: {
		notificationObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			appearance: {
				height: '100%',
				width: '100%',
				overflow: 'scroll'
				//position: 'absolute'
			},
			tab: null,
			notificationTabs: [
				{ label: 'panels.notification.historic', component: 'NotificationHistoric', value: 0 },
				{ label: 'panels.notification.realTime', component: 'NotificationActive', value: 1 }
			]
		};
	},
	computed: {
		realTimeElasticData: {
			get() {
				return this.$store.getters.getRealTimeElasticData;
			},
			set(value) {
				this.$store.commit('setRealTimeElasticData', value);
			}
		},
		selectedTabNotification: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		}
	},
	beforeCreate() {
		this.$vuetify.lang.current = this.$store.getters.getSession.language;
	},
	mounted() {
		if (Object.hasOwn(this.notificationObject, 'elasticindex') && this.realTimeElasticData.length === 0) {
			obtainPanelRealTimeData(
				this.notificationObject.code,
				this.notificationObject.pmdatasourcetypeid,
				this.notificationObject.elasticindex,
				this.$store
			);
		}
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
