export default {
	computed: {
		enableShowIntervals() {
			return this.form.designOption
				? this.form.designOption.showintervals == 1 && this.pollutant.thresholdminvalue !== null && this.pollutant.thresholdmaxvalue !== null
				: false;
		}
	},
	methods: {
		toggleShowIntervals(initMethod = 'initChart', deleteMethod = 'deleteChart') {
			console.log('toggleShowIntervals event', this.form.showIntervals);
			this.form.showIntervals = !this.form.showIntervals;
			if (this.form.showIntervals) {
				this[deleteMethod]();
				this.form.option = this[initMethod](
					this.pollutant.name,
					this.pollutant.symbol,
					this.form.chartData,
					this.pollutant.elasticacronym,
					this.form.designOption
				);
				console.log('intervals check', this.form.showIntervals, this.form.option);
			} else {
				this[deleteMethod]();
				this.loadingAnimation = true;
				setTimeout(() => {
					this.form.option = this[initMethod](
						this.pollutant.name,
						this.pollutant.symbol,
						this.form.chartData,
						this.pollutant.elasticacronym,
						this.form.designOption
					);
				}, 100);
				this.loadingAnimation = false;

				console.log('intervals check', this.form.showIntervals, this.form.option);
			}
		}
	}
};
