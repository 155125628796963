<template>
	<!--  echarts component -->
	<!-- <div ref="echart" class="d-flex justify-center align-center" :style="chartStyle" /> -->
	<v-chart v-if="option" :option="option" :style="chartStyle" autoresize></v-chart>
</template>

<script>
//import * as echarts from 'echarts';
import VChart from 'vue-echarts';
import constants from '@/utils/constants';
import { use } from 'echarts/core';
import { GaugeChart } from 'echarts/charts';
import { TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([TooltipComponent, GaugeChart, CanvasRenderer]);
export default {
	name: 'GaugeRealTime',
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			formDisabled: false,
			chartStyle: {},
			option: null
			//chartData: null
		};
	},
	components: { VChart },
	props: {
		feature: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: true
		},
		/* chartData: {
			type: Array,
			required: true
		}, */
		displayLocation: {
			type: String,
			required: true
		}
	},
	computed: {
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		realTimeElasticData() {
			return this.$store.getters.getRealTimeElasticData;
		},
		pollutantElasticData() {
			return this.realTimeElasticData[this.pollutant.elasticacronym][this.pollutant.elasticacronym];
		}
	},

	watch: {},
	created() {
		//console.log('created gauge', window.innerWidth, window.innerHeight);
		//console.log('displayMode', this.displayMode);
		this.chartStyle = this.displayLocation == 'card' ? { height: '200px', width: '200px' } : { height: '200px', width: '300px' };
	},
	mounted() {
		this.option = null;
		this.option = this.initChart(this.pollutantElasticData, this.pollutant.symbol);

		//console.log('gauge mounted', echartContainer.clientHeight, echartContainer.clientWidth);
		//console.warn(this.chart);
	},
	updated() {
		//console.log('gauge updated');
		//console.warn(this.chart);
	},
	beforeDestroy() {
		/* if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null; */
		//window.removeEventListener('resize', this.resizeChart);
	},
	methods: {
		resizeChart() {
			if (this.chart) {
				// Resize chart
				this.chart.resize();
			}
		},
		initChart(pollutantElasticData, pollutantSymbol) {
			let self = this;
			//this.chart = echarts.init(this.$refs['echart']);
			//window.addEventListener('resize', this.resizeChart);
			let statusColors = [];
			//let minStatusValue = 1;
			let maxStatusValue = 1;
			//var elasticAcronym = this.pollutant.elasticacronym;

			let parameterStatus = this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid);
			let statusArray = [];
			let hasUpperLimit = true;
			if (parameterStatus) {
				statusArray = JSON.parse(parameterStatus.status);
				const colors = JSON.parse(parameterStatus.color);
				statusArray.sort((a, b) => {
					const minimumA = a.min.data;
					const minimumB = b.min.data;
					if (minimumA < minimumB) {
						return -1;
					}
					if (minimumA > minimumB) {
						return 1;
					}
					return 0; // Objects have the same minimum (statement should be unreachable)
				});
				//minStatusValue = parameterStatus[0].minvalue;
				maxStatusValue = statusArray.at(-1).max.data;

				if (!maxStatusValue) {
					hasUpperLimit = false;
					if (pollutantElasticData >= statusArray.at(-1).min.data)
						maxStatusValue = pollutantElasticData + pollutantElasticData * (constants.RANGE_TOLERANCE / 100);
					else maxStatusValue = statusArray.at(-1).min.data + statusArray.at(-1).min.data * (constants.RANGE_TOLERANCE / 100);
				}
				statusArray.forEach((status, i) => {
					if (i == statusArray.length - 1) {
						statusColors.push([maxStatusValue / maxStatusValue + 0.01, colors[i]]);
					} else statusColors.push([status.max.data / maxStatusValue + 0.01, colors[i]]);
				});
			}
			let chartValue = pollutantElasticData / maxStatusValue;
			let chartData = [{ value: chartValue }];
			//console.debug('debug parameter status', this.pollutant.elasticacronym, parameterStatus, maxStatusValue, '---', chartValue, statusColors);
			let option;

			option = {
				tooltip: {
					show: true,
					formatter: function (params) {
						let range = null;
						let rangeArr = [];
						if (hasUpperLimit || pollutantElasticData < statusArray.at(-1).min.data)
							rangeArr = statusArray.filter((status) => params.value * maxStatusValue >= status.min.data);

						if (rangeArr.length > 0) range = rangeArr.at(-1);
						else range = statusArray.at(-1);
						let tooltip = '';
						if (range) {
							let min = range.min.op ? self.$t('pmstatus.' + range.min.op) + ' ' + range.min.data : null;
							let max = range.max.op ? self.$t('panels.notifications.' + range.max.op) + ' ' + range.max.data : null;
							if (min && max) {
								tooltip = `${self.pollutant.acronym}: ${range.min.data} - ${range.max.data}`;
							} else if (min) {
								tooltip = `${self.pollutant.acronym} ${min}`;
							} else if (max) {
								tooltip = `${self.pollutant.acronym} ${max}`;
							}
						}

						return tooltip;
					}
				},
				series: [
					{
						type: 'gauge',
						startAngle: 205,
						endAngle: -25,
						center: ['50%', '60%'],
						radius: '100%',
						min: /* minStatusValue ??  */ 0,
						max: 1,
						splitNumber: null, // hidden
						axisLine: {
							lineStyle: {
								width: 15,
								color: statusColors.length > 0 ? statusColors : [[1, '#6f7480']]
								/* [
									[0.105, '#58D9F9'],
									[0.225, '#7CFFB2'],
									[0.355, '#F1F517'],
									[0.47, '#E17E17'],
									[0.8, '#BE0E23'],
									[1, '#8F16AA']
								] */
							}
						},

						pointer: {
							show: !!statusArray.length > 0,
							icon: 'roundRect',
							length: '12%',
							width: 15,
							offsetCenter: [0, '-70%'],
							itemStyle: {
								color: 'auto'
							}
						},
						axisTick: {
							// hidden
							length: 0,
							lineStyle: {
								color: 'auto',
								width: 0
							}
						},
						splitLine: {
							// hidden
							length: 0,
							lineStyle: {
								color: 'auto',
								width: 0
							}
						},
						title: {
							offsetCenter: [0, '-20%'],
							fontSize: 24
						},
						detail: {
							fontSize: 22,
							offsetCenter: [0, 0],
							valueAnimation: true,
							formatter: function (value) {
								//return Math.round(value * 100) + ' ' + pollutantSymbol;
								/* console.log(
									'debug gauge length ' + pollutantSymbol,
									((value * 100).toFixed(2) + pollutantSymbol).length,
									pollutantSymbol.length
								); */

								let dataValue = value * maxStatusValue;
								//console.log('debug status chart', dataValue, maxStatusValue, chartValue);
								return (dataValue.toFixed(2) + pollutantSymbol).length > 9
									? dataValue.toFixed(2) + '\n' + pollutantSymbol
									: dataValue.toFixed(2) + ' ' + pollutantSymbol;
							},
							color: 'inherit'
						},
						data: chartData
					}
				]
			};
			console.log('gauge option', option, JSON.stringify(option));
			//option && this.chart.setOption(option);
			return option;
		}
	}
};
</script>

<style scoped>
.topOptions {
	display: flex;
	flex: auto;
	justify-content: space-between;
	align-content: center;
}
</style>
